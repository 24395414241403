import _ from 'lodash'
import * as utils from '@/word/utils'

const XML_ATTRS = {
  "xmlns:wpc": "http://schemas.microsoft.com/office/word/2010/wordprocessingCanvas",
  "xmlns:mc": "http://schemas.openxmlformats.org/markup-compatibility/2006",
  "xmlns:o": "urn:schemas-microsoft-com:office:office",
  "xmlns:r": "http://schemas.openxmlformats.org/officeDocument/2006/relationships",
  "xmlns:m": "http://schemas.openxmlformats.org/officeDocument/2006/math",
  "xmlns:v": "urn:schemas-microsoft-com:vml",
  "xmlns:wp14": "http://schemas.microsoft.com/office/word/2010/wordprocessingDrawing",
  "xmlns:wp": "http://schemas.openxmlformats.org/drawingml/2006/wordprocessingDrawing",
  "xmlns:w": "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
  "xmlns:w14": "http://schemas.microsoft.com/office/word/2010/wordml",
  "xmlns:w15": "http://schemas.microsoft.com/office/word/2012/wordml",
  "xmlns:w10": "urn:schemas-microsoft-com:office:word",
  "xmlns:wpg": "http://schemas.microsoft.com/office/word/2010/wordprocessingGroup",
  "xmlns:wpi": "http://schemas.microsoft.com/office/word/2010/wordprocessingInk",
  "xmlns:wne": "http://schemas.microsoft.com/office/word/2006/wordml",
  "xmlns:wps": "http://schemas.microsoft.com/office/word/2010/wordprocessingShape",
  "xmlns:wpsCustomData": "http://www.wps.cn/officeDocument/2013/wpsCustomData",
  "mc:Ignorable": "w14 w15 wp14"
}

class Comments {
  constructor(editor) {
    this.editor = editor
  }

  getAllFlatten() {
    var docStore = this.editor.docStore
    var comments = docStore.comments?.[0]?.children || []
    comments = comments.map(comment => {
      return {
        author: comment.attrs['w:author'],
        date: comment.attrs['w:date'].replace(/z$/i, ''),
        id: comment.attrs['w:id'],
        initials: comment.attrs['w:initials'],
        text: utils.getText(comment),
        paraIdList: comment.children.map(item => item.attrs?.['w14:paraId']).filter(item => item)
      }
    })
    var commentsDisplayInfo = this.editor.docStore.commentsDisplayInfo
    comments = comments.map(comment => {
      return {
        hidden: false,
        color: '#f8e6ab',
        zIndex: 0,
        ...commentsDisplayInfo[comment.id],
        ...comment
      }
    })
    return comments
  }

  getAll() {
    var comments = this.getAllFlatten()
    var commentsExtended = this.getCommentsExtended()
    comments.forEach(comment => {
      var matched = commentsExtended.find(item => comment.paraIdList.includes(item.paraId))
      if (matched) {
        Object.assign(comment, matched)
      }
    })

    var paraIdMap = comments.reduce((prev, comment) => {
      prev[comment.paraId] = comment
      return prev
    }, {})

    var needDeleteList = []

    for (var key in paraIdMap) {
      let val = paraIdMap[key]
      if (val.paraIdParent && paraIdMap[val.paraIdParent]) {
        paraIdMap[val.paraIdParent].replys = paraIdMap[val.paraIdParent].replys || []
        paraIdMap[val.paraIdParent].replys.push(val)
        needDeleteList.push(val)
      }
    }

    comments = comments.filter(comment => !needDeleteList.includes(comment))

    return comments
  }

  getAllWithPosition() {
    var allCommentsMap = this.getAllMap()
    var globalIndex = 0
    var paraPath = null
    var charIndex = 0
    this.editor.nodes({
      pass: (path, node) => {
        if (node.name === 'w:p') {
          paraPath = path
          charIndex = 0
        } else if (node.text) {
          globalIndex += node.text.length
          charIndex += node.text.length
        } else if (node.name === 'w:commentRangeStart') {
          var commentId = node.attrs['w:id']
          allCommentsMap[commentId].start = {
            globalIndex,
            paraPath,
            charIndex
          }
        } else if (node.name === 'w:commentRangeEnd') {
          var commentId = node.attrs['w:id']
          allCommentsMap[commentId].end = {
            globalIndex,
            paraPath,
            charIndex
          }
        }
      }
    })
    return Object.values(allCommentsMap)
  }

  getAllMap() {
    var allCommentsMap = this.getAll().reduce((prev, comment) => {
      prev[comment.id] = comment
      return prev
    }, {})
    return allCommentsMap
  }

  getCommentsExtended() {
    // 主要保存批注回复关系和是否完成
    var commentsExtended = this.editor.docStore.commentsExtended?.[0]?.children || []
    return commentsExtended.map(item => {
      var attrs = item.attrs
      return {
        paraId: attrs['w15:paraId'],
        done: attrs['w15:done'],
        paraIdParent: attrs['w15:paraIdParent']
      }
    })
  }

  updateStatus() {
    // 注意，这里面所有的赋值都要小心，因为每个赋值都会触发依赖更新，确定要更新才能赋值
    var commentsDisplayInfo = this.editor.docStore.commentsDisplayInfo
    var currentComments = {} // id: quoteText
    var allCommentsMap = this.getAllMap()
    var sectPr = null
    var currentPara = null
    this.editor.numbering.reset()
    var key2pathMap = this.editor.key2pathMap = {}
    // 初始化唯一的一次全局遍历
    this.editor.nodes({
      pass: (path, node) => {
        key2pathMap[node.id] = path
        if (!sectPr && node.name === 'w:sectPr') {
          // 默认使用第一个 sectPr, 优先确保前面的页面正确
          sectPr = node
        } else if (node.name === 'w:commentRangeStart') {
          var commentId = node.attrs['w:id']
          currentComments[commentId] = ''
        } else if (node.name === 'w:commentRangeEnd') {
          var commentId = node.attrs['w:id']
          commentsDisplayInfo[commentId] = commentsDisplayInfo[commentId] || {}
          commentsDisplayInfo[commentId].quoteText = currentComments[commentId]
          delete currentComments[commentId]
        } else if (node.name === 'w:r') {
          for (var commentId in currentComments) {
            currentComments[commentId] += utils.getText(node)
          }
          var commentIdList = Object.keys(currentComments)
          var commentStyle = this.getCommentStyle(commentIdList, allCommentsMap) || {}
          if (node.borderBottom !== commentStyle['border-bottom']) {
            node.borderBottom = commentStyle['border-bottom']
          }
          if (node.backgroundColor !== commentStyle['background-color']) {
            node.backgroundColor = commentStyle['background-color']
          }
          if (!_.isEqual(node.comments, commentIdList)) {
            node.comments = commentIdList
          }
        } else if (node.name === 'w:p') {
          currentPara = node
          var numStr = this.editor.numbering.getNumStr(node)
          if (numStr !== node.numStr) {
            node.numStr = numStr
          }
        } else if (node.name === 'w:instrText' && currentPara) {
          let arr = /_Toc\d+/.exec(node.text)
          if (arr && currentPara.tocId !== arr[0]) {
            currentPara.tocId = arr[0]
          }
        } else if (node.name === 'w:hyperlink' && currentPara) {
          let arr = /\w*_Toc\d+/.exec(node.attrs?.['w:anchor'] || '')
          if (arr && currentPara.tocId !== arr[0]) {
            currentPara.tocId = arr[0]
          }
        }
      }
    })
    this.editor.sectPr = sectPr
  }

  getCommentStyle(commentIdList, allCommentsMap) {
    if (commentIdList.length === 0) {
      return
    }
    var style = {}
    var docStore = this.editor.docStore
    var comments = commentIdList.map(commentId => allCommentsMap[commentId])
    comments = comments.filter(comment => {
      if (!comment) {
        return false
      }
      if (docStore.selectedCommentId == comment.id) {
        return true
      }
      if (comment.hidden === true) {
        return false
      }
      return true
    })
    if (comments.length === 0) {
      return
    }
    var topComment = _.maxBy(comments, comment => comment.zIndex)
    var color = topComment.color || '#f8e6ab'
    var activeBorderColor = topComment.activeBorderColor || 'rgb(250, 211, 85)'
    style['border-bottom'] = `2px solid ${color}`
    var hasSelectedComment = comments.some(item => item.id == docStore.selectedCommentId)
    if (hasSelectedComment) {
      style['background-color'] = color
      style['border-bottom'] = `2px solid ${activeBorderColor}`
    }
    return style
  }

  initCommentXML() {
    var list = this.editor.docStore.relationships?.[0]?.children
    var maxId = Math.max(...list.map(item => parseInt(/\d+/.exec(item.attrs.Id)[0])))
    this.tryAddToList(list, {
      name: 'Relationship',
      attrs: {
        Id: `rId${maxId + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/comments",
        Target: 'comments.xml'
      }
    }, 'Target')
    this.tryAddToList(list, {
      name: 'Relationship',
      attrs: {
        Id: `rId${maxId + 2}`,
        Type: "http://schemas.microsoft.com/office/2011/relationships/commentsExtended",
        Target: 'commentsExtended.xml'
      }
    }, 'Target')
  }

  tryAddToList(list, value, attrKey) {
    var exist = _.some(list, item => item.attrs[attrKey] === value.attrs[attrKey])
    if (!exist) {
      list.push(value)
    }
  }

  initCommentForDocx() {
    this.initCommentXML() // 在 rels 中加入 comment
    this.editor.docStore.comments = [{
      attrs: XML_ATTRS,
      children: [],
      name: 'w:comments'
    }]
    this.editor.docStore.commentsExtended = [{
      name: 'w15:commentsEx',
      attrs: XML_ATTRS,
      children: []
    }]
    var contentTypes = this.editor.docStore.contentTypes[0].children
    this.tryAddToList(contentTypes, {
      name: 'Override',
      attrs: {
        PartName: '/word/comments.xml',
        ContentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.comments+xml'
      }
    }, 'PartName')
    this.tryAddToList(contentTypes, {
      name: 'Override',
      attrs: {
        PartName: '/word/commentsExtended.xml',
        ContentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.commentsExtended+xml'
      }
    }, 'PartName')
  }

  insert(range, comment) {
    // editor.insertComment(editor.getSelectRange(), {text: 'comment text'})
    comment = Object.assign({}, comment)
    var { start, end } = range
    var comments = this.editor.docStore.comments?.[0]?.children
    if (!comments) {
      this.initCommentForDocx()
      comments = this.editor.docStore.comments?.[0]?.children
    }
    var idList = _.map(comments, comment => Number(comment.attrs['w:id']))
    var id = Math.max(Math.max(...idList), 0) + 1
    comment.id = id
    if (utils.isEmptyRange(range)) {
      this.editor.insertElement(start, {
        name: 'w:commentRangeStart',
        id: _.uniqueId(),
        attrs: {
          'w:id': id
        }
      }, {
        name: 'w:commentRangeEnd',
        id: _.uniqueId(),
        attrs: {
          'w:id': id
        }
      }, {
        name: 'w:r',
        id: _.uniqueId(),
        children: [{
          name: 'w:commentReference',
          attrs: {
            'w:id': id
          }
        }]
      })
    } else {
      // commentReference 在 commentRangeEnd 后面
      this.editor.insertElement(end, {
        name: 'w:commentRangeEnd',
        id: _.uniqueId(),
        attrs: {
          'w:id': id
        }
      }, {
        name: 'w:r',
        id: _.uniqueId(),
        children: [{
          name: 'w:commentReference',
          attrs: {
            'w:id': id
          }
        }]
      })
      this.editor.insertElement(start, {
        name: 'w:commentRangeStart',
        id: _.uniqueId(),
        attrs: {
          'w:id': id
        }
      })
    }

    this.insertToCommentsXML(comment)
    this.insertToCommentsExtendedXML(comment)
    return id
  }

  insertToCommentsXML(comment) {
    var comments = this.editor.docStore.comments?.[0]?.children
    var author = comment.author || this.editor.config?.author
    var id = comment.id
    var date = utils.changeTimeZone(comment.date).toISOString() || utils.changeTimeZone().toISOString()
    var children = this.editor.createParas(comment.text || '')
    comments.push({
      name: 'w:comment',
      attrs: {
        'w:id': id,
        'w:author': author,
        'w:date': date,
        'w:initials': ''
      },
      children
    })

    var paraId = children.slice(-1)[0].attrs['w14:paraId']
    comment.paraId = paraId

    // 记录批注显示专用信息
    this.editor.docStore.commentsDisplayInfo[id] = {
      color: comment.color,
      activeBorderColor: comment.activeBorderColor,
      hidden: comment.hidden,
      quoteText: '',
      temp: comment.temp,
      zIndex: comment.zIndex || 0 // 默认是 0
    }
    if (!comment.temp) {
      this.editor.emit('comment', {
        type: 'insert',
        comment: {
          author,
          date: date.replace(/z$/i, '')
        }
      })
    }
  }

  insertToCommentsExtendedXML(comment) {
    var commentsExtended = this.editor.docStore.commentsExtended?.[0]
    if (!commentsExtended) {
      return
    }
    commentsExtended.children = commentsExtended.children || []
    commentsExtended.children.push({
      name: 'w15:commentEx',
      attrs: {
        'w15:paraId': comment.paraId,
        'w15:done': comment.done ? '1' : '0',
        'w15:paraIdParent': comment.paraIdParent
      }
    })
  }

  reply(parentCommentId, comment) {
    // 1. document 中在爸爸批注后面打 start end reference
    // 2. 插入 comments.xml
    // 3. 插入 commentsExtended.xml
    comment = Object.assign({}, comment)
    var parentComment = this.getAllMap()[parentCommentId]
    comment.paraIdParent = parentComment.paraId
    var lastCommentId = parentComment.id
    if (parentComment.replys) {
      lastCommentId = parentComment.replys.slice(-1)[0].id
    }
    var comments = this.editor.docStore.comments?.[0]?.children
    var idList = _.map(comments, comment => Number(comment.attrs['w:id']))
    var id = Math.max(Math.max(...idList), 0) + 1
    comment.id = id
    var startPath, endPath
    this.editor.nodes({
      pass: (path, node) => {
        if (node.name === 'w:commentRangeStart' && node.attrs['w:id'] == lastCommentId) {
          startPath = path
        } else if (node.name === 'w:commentReference' && node.attrs['w:id'] == lastCommentId) {
          endPath = path.slice(0, -1)
        }
      }
    })
    if (startPath && endPath) {
      this.editor.appendToPath(endPath, {
        name: 'w:commentRangeEnd',
        id: _.uniqueId(),
        attrs: {
          'w:id': id
        }
      }, {
        name: 'w:r',
        id: _.uniqueId(),
        children: [{
          name: 'w:commentReference',
          attrs: {
            'w:id': id
          }
        }]
      })
      this.editor.appendToPath(startPath, {
        name: 'w:commentRangeStart',
        attrs: {
          'w:id': id
        }
      })
    }
    this.insertToCommentsXML(comment)
    if (comment.paraIdParent) {
      this.insertToCommentsExtendedXML(comment)
    }
  }

  update(commentId, comment) {
    comment = Object.assign({}, comment)
    var comments = this.editor.docStore.comments[0]?.children
    var displayInfo = this.editor.docStore.commentsDisplayInfo[commentId]
    var matched = _.find(comments, comment => comment.attrs['w:id'] == commentId)
    if (!matched) return
    if (comment.text) {
      // 保持 paraId 不变, 确保 commentsExtend 正确的父子关联
      var paraId = matched.children.slice(-1)[0].attrs['w14:paraId']
      matched.children = this.editor.createParas(comment.text || '')
      matched.children.slice(-1)[0].attrs['w14:paraId'] = paraId
    }
    if (comment.date) {
      matched.attrs['w:date'] = comment.date.toISOString()
    }
    if (comment.author) {
      matched.attrs['w:author'] = comment.author
    }
    if (displayInfo?.temp) {
      displayInfo.temp = false
      this.editor.emit('comment', {
        type: 'insert',
        comment: {
          author: matched.attrs['w:author'],
          date: matched.attrs['w:date'].replace(/z$/i, '')
        }
      })
    }
  }

  select(commentId) {
    var docStore = this.editor.docStore
    docStore.selectedCommentId = commentId
    var el = this.scrollTo(commentId)
    var comment = this.getAllMap()[commentId]
    if (comment) {
      docStore.activeComment = {
        top: this.editor.getOffset(el).top,
        commentId,
        ...comment
      }
    }

    this.updateStatus()
  }

  scrollTo(commentId) {
    var el = this.editor.root.querySelector(`[data-comment-id="${commentId}"]`)
    if (el) {
      if (el.scrollIntoViewIfNeeded) {
        el.scrollIntoViewIfNeeded()
      } else {
        el.scrollIntoView()
      }
      return el
    }
  }

  delete(commentId) {
    var tagList = ['w:commentRangeStart', 'w:commentReference', 'w:commentRangeEnd']
    var needRemove = []
    this.editor.nodes({
      pass: (path, node) => {
        if (tagList.includes(node.name) && node.attrs['w:id'] == commentId) {
          needRemove.push(path)
        }
      }
    })
    for (let path of needRemove.reverse()) {
      this.editor.removeElement(path)
    }
    var comments = this.editor.docStore.comments[0]?.children
    _.remove(comments, comment => {
      return comment.attrs['w:id'] == commentId
    })
  }

  deleteBatch(commentIdList) {
    var tagList = ['w:commentRangeStart', 'w:commentReference', 'w:commentRangeEnd']
    var needRemove = []
    this.editor.nodes({
      pass: (path, node) => {
        if (tagList.includes(node.name)) {
          var id = Number(node.attrs['w:id'])
          if (commentIdList.includes(id)) {
            needRemove.push(path)
          }
        }
      }
    })
    for (let path of needRemove.reverse()) {
      this.editor.removeElement(path)
    }
    var comments = this.editor.docStore.comments[0]?.children
    _.remove(comments, comment => {
      var id = Number(comment.attrs['w:id'])
      return commentIdList.includes(id)
    })
  }

  deleteAll() {
    var tagList = ['w:commentRangeStart', 'w:commentReference', 'w:commentRangeEnd']
    var needRemove = []
    this.editor.nodes({
      pass: (path, node) => {
        if (tagList.includes(node.name)) {
          needRemove.push(path)
        }
      }
    })
    for (let path of needRemove.reverse()) {
      this.editor.removeElement(path)
    }
    var commentRoot = this.editor.docStore.comments[0]
    if (commentRoot) {
      commentRoot.children = []
    }
  }

  hide(commentId) {
    const displayInfo = this.editor.docStore.commentsDisplayInfo
    if (displayInfo[commentId]) {
      displayInfo[commentId].hidden = true
    }
  }

  show(commentId) {
    const displayInfo = this.editor.docStore.commentsDisplayInfo
    if (displayInfo[commentId]) {
      displayInfo[commentId].hidden = false
    }
  }
}

export default Comments
