import * as utils from '@/word/utils'

const numValueMap = {
  // decimal: '123456789',
  japaneseCounting: '零一二三四五六七八九十百千',
  japaneseCountingThousand: '零一二三四五六七八九十百千',
  chineseCounting: '零一二三四五六七八九十百千',
  chineseCountingThousand: '零一二三四五六七八九十百千'
}

const LOWER_ROMAN = ['m', 'cm', 'd', 'cd', 'c', 'xc', 'l', 'xl', 'x', 'ix', 'v', 'iv', 'i', ' ']
const UPPER_ROMAN = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I', ' ']
const ROMAN_VALS = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1, 0]

class Numbering {
  constructor(editor) {
    this.editor = editor
    this.numState = {}
  }

  reset() {
    this.numState = {}
  }

  getNumStr(para) {
    var editor = this.editor
    var numPr = editor.rawPr2dict(editor.getElementByNamePath('pPr.numPr', para))
    if (!(numPr && numPr.numId)) {
      var styleId = editor.getElementByNamePath('pPr.pStyle.val', para)
      var style = editor.docStore.styleIdMap[styleId]
      numPr = style?.pPr?.numPr
    }
    if (!numPr) {
      return ''
    }
    var vanish = editor.getElementByNamePath('pPr.rPr.vanish', para)
    if (vanish) {
      return ''
    }

    var numInfo = this.getNumInfo(numPr)
    if (!numPr || !numInfo) {
      return ''
    }
    var state = this.increment(numPr.numId.val, numPr.ilvl?.val || 0, numInfo)
    return numInfo.lvlText.val.replace(/%(\d)/g, function(_, count) {
      var ret = state[count - 1] || 1 // 暂定默认值是1
      var numFmt = numInfo.numFmt.val
      var translateString = numValueMap[numFmt]
      if (translateString) {
        ret = utils.translateNum(ret, translateString)
      } else if (numFmt === 'decimalEnclosedCircle') {
        if (ret <= 20) {
          ret = String.fromCharCode(0x2460 + Number(ret) - 1)
        }
      } else if (numFmt === 'upperLetter' || numFmt === 'lowerLetter') {
        var delta = numFmt === 'upperLetter' ? 65 : 97
        var num = ret - 1
        var count = (num - num % 26) / 26
        ret = String.fromCharCode(num % 26 + delta)
      } else if (numFmt === 'lowerRoman' || numFmt === 'upperRoman') {
        ret = getRoman(ret, numFmt)
      }
      if (ret === undefined) {
        // 目前不知其规则
        console.log('unknown num', this.numState, numPr)
        ret = ''
      }
      return ret
    })
  }

  getNumInfo(numPr) {
    var numId = numPr.numId?.val
    var ilvl = numPr.ilvl?.val || 0
    var numObj = this.editor.docStore.numMap?.[numId]
    var startList = {}
    if (numObj) {
      var ret = numObj[ilvl]
      if (!ret) {
        return null
      }
      for (var key in numObj) {
        startList[key] = numObj[key].start?.val
      }
      ret.startList = startList
      return ret
    }
  }

  increment(numId, ilvl, numInfo) {
    // 序号计数 {numId: [0count, 1count, 2count]}
    // 类似于 css 的 counter 和 reset
    ilvl = parseInt(ilvl)
    var numState = this.numState
    window.docxjsNumState = numState
    numState[numId] = numState[numId] || []
    var isFirst = numState[numId]?.[ilvl] == null
    for (var i = 0; i <= ilvl; i++) {
      // levelN 需要用前面 level1-N的start值
      numState[numId][i] = numState[numId][i] || numInfo.startList[i]
    }
    if (!isFirst) {
      numState[numId][ilvl]++
      numState[numId][ilvl + 1] = -1 // reset
    }
    return numState[numId]
  }
}

function getRoman(num, numFmt) {
  var ret = ''
  var rims

  if (numFmt === 'lowerRoman')
    rims = LOWER_ROMAN
  else
    rims = UPPER_ROMAN

  var nIndex = 0
  while (num > 0) {
    while (ROMAN_VALS[nIndex] <= num) {
      ret += rims[nIndex]
      num -= ROMAN_VALS[nIndex]
    }

    nIndex++

    if (nIndex >= rims.length)
      break
  }
  return ret
}

export default Numbering
