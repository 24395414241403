
<template>
  <span class="word-t" v-html="text"></span>
</template>
<script>
import WordMixin from '@/word/word-mixin'
import _ from 'lodash'

export default {
  mixins: [WordMixin],
  computed: {
    text() {
      // http://www.javashuo.com/article/p-crkwqnss-mm.html
      // &nbsp; 不换行空格
      // &ensp; 半角空格一个英文/数字宽度, em宽度的一半, 理论上应该是这个, 效果最贴近部分 word
      // &emsp; 全角空格, 一个中文字符宽度 = 1em
      // &thinsp; 窄空格，1/6em, 效果最贴近部分 word
      // 空格用的是西文字体, 如果是 Times New Roman, 则对应窄空格, 如果是中文, 则应该是半角空格
      // mac 中宋体等空格不是严格的1/2em，但word 的设定就是空格是 1/2em，因此理论上只能用 &ensp
      // 使用 &ensp 代价就是复制需要转换空格
      if (this.element.text) {
        // return this.element.text // 先用原生空格，确保复制内容正确，原生空格也是0.5em
        return this.element.text.replace(/ /g, '&ensp;') // 经过测试, 空格确实占半角
      }
      return ''
    }
  }
}
</script>
