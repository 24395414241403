// parser, serialize 库需要同时支持browser 和 nodejs
const JSZIP = require('jszip')
const _ = require('lodash')
const OoxmlParser = require('./ooxmlparser')
class Parser {
  async parseDocx(docxContent) {
    // https://stuk.github.io/jszip/documentation/api_jszip/file_name.html
    const zip = await JSZIP.loadAsync(docxContent)
    this.zip = zip
    this.relationships = await this.getJsonFromXML('word/_rels/document.xml.rels')
    this.numbering = await this.getJsonFromXML('word/numbering.xml')
    this.document = await this.parseDocument()
    this.comments = await this.getJsonFromXML('word/comments.xml')
    this.commentsExtended = await this.getJsonFromXML('word/commentsExtended.xml')
    this.styles = await this.getJsonFromXML('word/styles.xml')
    this.contentTypes = await this.getJsonFromXML('[Content_Types].xml')
    this.docPropsCore = await this.getJsonFromXML('docProps/core.xml')
    this.docPropsApp = await this.getJsonFromXML('docProps/app.xml')
    this.settings = await this.getJsonFromXML('word/settings.xml')

    var ret = {
      document: this.document,
      numbering: this.numbering,
      comments: this.comments,
      commentsExtended: this.commentsExtended,
      contentTypes: this.contentTypes,
      relationships: this.relationships,
      styles: this.styles,
      docPropsCore: this.docPropsCore,
      docPropsApp: this.docPropsApp,
      settings: this.settings,
      zip
    }

    return ret
  }

  async parseDocument() {
    var json = await this.getJsonFromXML('word/document.xml')
    this.normalizeNode(json[0]) // 需要补充id来达成渲染缓存
    return json
  }

  normalizeNode(element) {
    element.id = _.uniqueId()

    if (element.name === 'w:r') {
      // 提前准备批注属性
      element.comments = []
      // 确保最高性能
      element.borderBottom = null
      element.backgroundColor = null
    } else if (element.name === 'w:p') {
      element.numStr = ''
      element.tocId = ''
    }

    _.each(element.children, item => {
      this.normalizeNode(item)
    })
  }

  async getJsonFromXML(filePath) {
    var file = this.zip.file(filePath)
    if (!file) {
      return null
    }
    var xmlString = await file.async('string')
    var ooxmlparser = new OoxmlParser()
    var json = ooxmlparser.parse(xmlString)
    return json.children
  }
}

module.exports = Parser
