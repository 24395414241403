<template>
  <div class="word-table-of-content">
    <div
      v-for="(item, i) in list"
      :key="i"
      class="word-toc-item"
      :style="{ 'padding-left': 2 * (item.level - 1) + 'em' }"
      @click="handleClick(item)">
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  inject: ['getApiInstance'],
  created() {
    this.list = this.getApiInstance().getTOC()
  },
  methods: {
    handleClick(item) {
      this.getApiInstance().scrollToId(item.id)
    }
  }
}
</script>

<style scoped lang="scss">
.word-table-of-content {
  user-select: none;
}
.word-toc-item {
  cursor: pointer;
}
</style>
