import _ from 'lodash'
import * as utils from '@/word/utils.js'

export default {
  props: {
    element: Object
  },
  inject: ['getApiInstance'],
  methods: {
    pt2px: utils.pt2px,
    emu2css: utils.emu2css,
    dxa2css: utils.dxa2css,
    dxa2px: utils.dxa2px,
    getParentNode(node) {
      return node.$parent.$parent
    },
    getPath() {
      var path = []
      var currentNode = this
      var parentNode = this.getParentNode(currentNode)
      while (parentNode && parentNode.element) {
        var index = parentNode.element.children.indexOf(currentNode.element)
        path.unshift(index)
        currentNode = parentNode
        parentNode = this.getParentNode(parentNode)
      }
      return path
    }
  }
}
