<template>
  <span
    class="word-comment-range-start"
    :data-path="getPath().join('-')"
    :data-comment-id="id"></span>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import rangeStore from '@/components/rangestore'

export default {
  mixins: [WordMixin],
  computed: {
    id() {
      return this.element.attrs['w:id']
    }
  },
  created() {
    var point = {
      path: this.getPath(),
      offset: 0
    }
    var id = this.element.attrs?.['w:id']
    rangeStore.addStart(`comment-${id}`, point)
  }
}
</script>
