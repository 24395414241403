<template>
  <span class="word-ins" @click="handleClick">
    <word-num v-if="element.rPr && element.rPr.numPr" :numPr="element.rPr.numPr"></word-num>
    <slot></slot>
  </span>
</template>

<script>
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  methods: {
    handleClick(ev) {
      var editor = this.getApiInstance()
      var attrs = this.element.attrs
      var date = attrs['w:date']
      var author = attrs['w:author']
      editor.docStore.activeComment = {
        author,
        date,
        text: '插入了内容',
        top: editor.getOffset(ev.target).top
      }
    }
  }
}
</script>
