<template>
  <div class="home">
    <div class="tool">
      <div class="row">
        <div>
          <label>
            <span class="word-btn word-btn-text">点击或拖拽上传文件</span>
            <input type="file" @change="handleFileChange" accept=".docx,.zip" hidden>
          </label>
        </div>
        <span class="file-name">{{ fileName }}</span>
        <span class="word-btn word-btn-text" @click="exportDocx">导出</span>
        <span class="word-btn word-btn-text" @click="reviewContract">审查</span>
        <!-- 有safari不支持的正则，先忽略 -->
        <!-- <Toolbox></Toolbox> -->
      </div>
      <div class="row zoom">
        <span class="word-btn word-btn-text" @click="scale -= 10">-</span>
        <!-- <input type="text" v-model="scale"> -->
        <span class="scale">{{ scale }}%</span>
        <span class="word-btn word-btn-text" @click="scale += 10">+</span>
      </div>
    </div>
    <div class="editor-wrapper">
      <Editor
        ref="editor"
        :file="file"
        :options="options"
        @error="handleError"
        @ready="handleReady"
        @comment="handleComment">
        <template v-slot:default="scope">
          <div class="right-sidebar" contenteditable="false">
            <select v-model="showType">
              <option value="review">审查结果</option>
              <option value="zip">zip</option>
            </select>
            <ReviewResults v-if="showType === 'review'" :results="reviewResults"></ReviewResults>
            <ZipContent v-else :zip="scope.zip"></ZipContent>
          </div>
        </template>
      </Editor>
    </div>

    <!-- <Editor :file="file" :options="{ canEdit: false }"></Editor> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Editor from '@/components/Editor.vue'
import ZipContent from '@/components/ZipContent'
import axios from 'axios'
// import Toolbox from '@/components/Toolbox'

export default {
  name: 'Home',
  components: {
    Editor,
    ZipContent,
    // Toolbox
  },
  data() {
    return {
      showType: 'zip',
      file: null,
      options: null,
      scale: 100,
      fileName: ''
    }
  },
  watch: {
    scale(val) {
      this.scale = this.$refs.editor.setScale(val)
    }
  },
  async created() {
    this.bindEvents()
    var fileUrl = '/test.docx'
    // fileUrl = '/test/李宁测试合同.docx'
    // fileUrl = '/test/承揽合同（工商总局2000版）.docx'
    // fileUrl = '/test/表格左移.docx'
    // fileUrl = '/test/高保真异常.docx'
    // fileUrl = '/test/文件名_后缀.docx'
    // fileUrl = '/test/麦当劳业主合同.docx'
    // fileUrl = '/test/普通文本框.docx'
    // var fileUrl = '/商铺租赁合同01-承租方.docx'
    // fileUrl = '/test/table虚线.docx'
    // fileUrl = '/test/电梯供货合同.docx'
    // fileUrl = '/test/文本框-部分接受修订.docx'
    // fileUrl = '/test/文本框.docx'
    // fileUrl = '/comment.docx'
    // fileUrl = '/revision.docx'
    // fileUrl = '/test/居理新房服务器采购合同-20181210-原始-mecheck.docx'
    // fileUrl = '/test/电梯采购合同.docx'
    // fileUrl = '/test/标题1.docx'
    const res = await axios.get(fileUrl, {
      responseType: 'blob'
    })
    res.data.name = fileUrl.split('/').pop()
    this.handleFile(res.data)

  },
  methods: {
    bindEvents() {
      document.addEventListener('drop', ev => {
        ev.preventDefault()
        var file = ev.dataTransfer.files[0]
        this.handleFile(file)
      })
      document.addEventListener('dragover', ev => {
        ev.preventDefault()
      })
    },
    async handleFile(file) {
      if (file) {
        var editor = this.$refs.editor
        this.currentFile = file
        this.fileName = file.name
        if (/docx$/i.test(file.name)) {
          editor.handleDocxBlob(file, {
            canEdit: true,
            canComment: true
          })
        }
      }
    },
    async handleFileChange(ev) {
      var file = ev.target.files[0]
      this.handleFile(file)
    },
    async reviewContract(blob) {
      var editor = this.$refs.editor.editor
      var blob = editor.blob
      if (!(blob instanceof File)) {
        blob = new File([blob], 'default.docx', {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        })
      }
      var formData = new FormData()
      formData.append('type', 'house_lease')
      formData.append('file', blob)
      var { data } = await axios.post('/api/review', formData)
      var labels = data.results[0]?.res?.labels
      var results = []
      for (var key in labels) {
        labels[key].labels.forEach(item => {
          if (item.pos) {
            item.pos.forEach(pos => {
              if (item.value !== '通过') {
                results.push({
                  commentId: null,
                  pos,
                  suggestion: item.suggestion,
                  type: item.type,
                  value: item.value
                })
              }
            })
          }
        })
      }
      // 排序
      results.sort((a, b) => {
        var paraA = Number(a.pos.coordinates)
        var paraB = Number(b.pos.coordinates)
        if (paraA > paraB) {
          return 1
        } else if (paraA < paraB) {
          return -1
        } else {
          var aLeft = a.pos.left || 0
          var bLeft = b.pos.left || 0
          if (aLeft > bLeft) {
            return 1
          } else if (bLeft > aLeft) {
            return -1
          }
        }
      })
      console.log('review results', results)
      // this.reviewResults = results // 现在没用到
      var start = Date.now()
      await editor.handleAction(() => {
        for (let i = 0; i < results.length; i++) {
          let result = results[i]
          var { pos } = result
          var range = editor.getRange(pos.coordinates, pos.left, pos.coordinates, pos.right)
          if (range) {
            var commentId = editor.comments.insert(range, {
              text: editor.escapeCommentText(result.suggestion)
            })
            result.commentId = commentId
          }
          // if (i % 10 === 9) {
          //   await this.sleep(0) // 懒加载
          // }
        }
      })
      setTimeout(() => {
        console.log('打批注耗时', results.length + '个', Date.now() - start + 'ms')
      })
    },
    handleError(err) {
      console.log('docxjs报错', err)
    },
    handleReady() {
      console.log('ready')
    },
    handleComment(ev) {
      console.log('批注相关事件', ev)
    },
    exportDocx() {
      var editor = this.$refs.editor
      editor.exportDocx()
    }
  }
}
</script>

<style scoped lang="scss">
.right-sidebar {
  position: absolute;
  top: 0;
  right: -300px;
  width: 280px;
  select {
    margin-bottom: 20px;
  }
}

.editor-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: calc(100vh - 40px);
  max-width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  transform-origin: 0 0;
}

.tool {
  box-shadow: 0 -1px 0 0 #e2e6ed;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: #f2f4f7;
  color: #3d4757;
  font-size: 12px;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  input {
    font-size: 12px;
  }
  input[type="text"] {
    width: 30px;
    text-align: center;
  }
  button {
    height: 20px;
    min-width: 20px;
    margin: 0 10px;
    padding: 0;
    font-size: 12px;
  }
}

.row {
  display: flex;
}

.zoom {
  align-items: center;
  .scale {
    width: 45px;
    text-align: center;
  }
  .word-btn {
    font-size: 20px;
    font-family: cursive;
    font-weight: 900;
  }
}

.file-name {
  line-height: 26px;
}

@media screen and (max-width: 800px) {
  .editor-wrapper {
    padding: 0;
    max-height: 100vh;
  }
  .tool {
    display: none;
  }
}
</style>
