const JSZIP = require('jszip')
const OoxmlSerializer = require('./ooxmlserializer')

class Serializer {
  async format(doc) {
    var { zip } = doc
    var newZip = new JSZIP()
    var supportedKeys = {
      'word/_rels/document.xml.rels': 'relationships',
      'word/numbering.xml': 'numbering',
      'word/document.xml': 'document',
      'word/comments.xml': 'comments',
      'word/commentsExtended.xml': 'commentsExtended',
      'word/styles.xml': 'styles',
      '[Content_Types].xml': 'contentTypes'
    }
    for (let key in zip.files) {
      if (zip.files[key].dir) {
        // 目录
        newZip.folder(key)
      } else if (supportedKeys[key]) {
        continue
        // var xml = this.getXMLFromJson(doc.docStore[supportedKeys[key]])
        // xml = xml.split('&').join('&amp;') // 脏处理, 不能有 &
        // // 高保真调试代码, 误删
        // var rawXml = await (zip.file(key)).async('string')
        // if (xml !== rawXml) {
        //   this.findDiff(xml, rawXml)
        //   debugger
        // }
        // newZip.file(key, xml)
      } else {
        newZip.file(key, zip.file(key).async('blob'))
      }
    }
    for (let key in supportedKeys) {
      var file = supportedKeys[key]
      if (doc.docStore[file]) {
        var xml = this.getXMLFromJson(doc.docStore[file])
        newZip.file(key, xml)
      }
    }
    var zipBlob = await newZip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 6 // 6最接近
      }
    })
    return zipBlob
  }

  findDiff(strA, strB) {
    for (var i = 0; i < strA.length; i++) {
      if (strA[i] !== strB[i]) {
        console.log('find diff', i, '\n', strA.slice(i - 30, i + 30), '\n', strB.slice(i - 30, i + 30))
        return
      }
    }
  }

  getXMLFromJson(json) {
    var ooxmlSerializer = new OoxmlSerializer()
    var xml = ooxmlSerializer.format(json)
    return xml
  }

  debug$($) {
    var file = new File([$.xml()], 'document.xml', {
      type: 'text/xml' // 默认使用 text/xml, 例如 .rels
    })
    var url = URL.createObjectURL(file)
    window.open(url, '_blank')
    console.log($.xml())
  }
}

module.exports = Serializer
