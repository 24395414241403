const cheerio = require('cheerio')

class Serializer {
  format(json) {
    return `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\r\n` + this.getXMLFromJson(json)
  }

  getXMLFromJson(json) {
    var $ = cheerio.load('', {
      xml: {
        decodeEntities: false,
        normalizeWhitespace: false // 可以区分大小写
      }
    })
    this.$ = $
    this.fillXML($.root(), json)
    return $.xml()
  }

  fillXML(node, obj) {
    if (Array.isArray(obj)) {
      obj.forEach(child => {
        var childNode = this.$(`<${child.name}>`)
        this.fillXML(childNode, child)
        node.append(childNode)
      })
      return
    }
    for (var key in obj) {
      var val = obj[key]
      if (key === 'name') {
        continue
      }
      if (key === 'children') {
        this.fillXML(node, val)
      } else if (key === 'attrs') {
        for (var name in val) {
          node.attr(name, escapeXml(val[name]))
        }
      } else if (key === 'text' && !('children' in obj)) {
        // 有 children 就不可能有 text 了
        node.text(escapeXml(val))
      }
    }
  }
}

function escapeXml(str) {
  // 正确处理 & < > 这三个符号都会报错
  str = _.toString(str)
  return str.split('&').join('&amp;').split('<').join('&lt;').split('>').join('\&gt;')
}

module.exports = Serializer
