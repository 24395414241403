import _ from 'lodash'
import * as Path from '@/path'

const BLOCK_TAGS = ['p', 'tbl']
const INLINE_TAGS = ['r', 'del', 'ins', 'commentRangeStart', 'commentRangeEnd', 'commentReference', 'bookmarkStart', 'bookmarkEnd', 'drawing', 'hyperlink', 'AlternateContent', 'shape', 'rect', 'txbxContent', 'sdt', 'sdtContent', 'tr', 'tc', 'pict']
const TEXT_TAGS = ['t', 'delText', 'tab', 'br', 'object'] // run 下面只会有一个 text tag, 目前看不可能同时存在
export const SUPPORTED_TAGS = new Set([].concat(BLOCK_TAGS, INLINE_TAGS, TEXT_TAGS))
export const CONTENT_TAGS = ['t', 'r', 'p', 'tbl', 'del', 'ins']
export const FIRST_MAIN_TAGS = ['p', 'tbl', 'sdtContent'] // 第一层支持的标签，和parser同步

const highlightPool = [
  {
    name: 'yellow',
    text: '黄色',
    value: '#FFFF00'
  },
  {
    name: 'green',
    text: '鲜绿',
    value: '#00ff00'
  },
  {
    name: 'cyan',
    text: '青绿',
    value: '#00FFFF'
  },
  {
    value: '#FF00FF'
  },
  {
    value: '#0000FF'
  },
  {
    value: '#FF0000'
  },
  {
    value: '#00008B'
  }
]

export function removeNS(str) {
  if (!str) {
    debugger
  }
  return str.split(':').slice(-1)[0]
}

export function filterAndIndexElements(elements) {
  return _.filter(elements, item => {
    var name = removeNS(item.name)
    if (SUPPORTED_TAGS.has(name)) {
      return true
    }
    return false
  })
}

export function getText(element) {
  if (!element) {
    return ''
  }
  if (Array.isArray(element.children)) {
    var ret = element.children.map(function(item) {
      return getText(item)
    }).join('')
    if (element.name === 'w:p') {
      ret += '\n'
    }
    return ret
  } else if (element.text) {
    return element.text
  } else if (element.name === 'w:tab') {
    if (_.isEmpty(element.attrs)) {
      return ' '
    }
  } else if (element.name === 'w:br') {
    return ' '
  }
  return ''
}

export function changeTimeZone(date) {
  date = date || new Date()
  var timestamp = date.getTime() - date.getTimezoneOffset() * 60 * 1000
  var ret = new Date(timestamp)
  return ret
}

export function props2style(props) {
  // word props 转成 css style
  if (!props) {
    return null
  }
  var style = {}
  for (var key in props) {
    var val = props[key]
    if (key === 'jc') {
      style['text-align'] = val.val === 'both' ? 'justify' : val.val
    } else if (key === 'sz') {
      style['font-size'] = val.val / 2 + 'pt'
    } else if (key === 'b') {
      style['font-weight'] = isPropFalse(val) ? 'normal' : 'bold'
    } else if (key === 'i') {
      style['font-style'] = isPropFalse(val) ? 'normal' : 'italic'
    } else if (key === 'rFonts') {
      var fontFamily = normalizeFontFamily(val['eastAsia']) || normalizeFontFamily(val['ascii'])
      if (fontFamily) {
        style['font-family'] = fontFamily + ',宋体,宋体-简' // 总是降级到宋体
      }
    } else if (key === 'u') {
      style['text-decoration'] = isPropFalse(val) ? 'none' : 'underline'
      style['text-underline-offset'] = '1px' // 防止和下划线字符重叠
      if (val.val === 'wave') {
        style['text-decoration-style'] = 'wavy'
      }
    } else if (key === 'em') {
      style['text-emphasis'] = 'dot black'
      style['text-emphasis-position'] = `${val?.val === 'comma' ? 'top' : 'under'} left`
    } else if (key === 'strike') {
      style['text-decoration'] = isPropFalse(val) ? 'none' : 'line-through'
    } else if (key === 'spacing') {
      // 字符间距 - 加宽
      // 暂不支持，优先让字更紧凑
      // style['letter-spacing'] = val?.val / 100 + 'pt'
    } else if (key === 'w') {
      // 字符间距 - 缩放
      // 本应该用 font-stretch，但不支持就不用
      if (val?.val && val.val < 100) {
        // 仅处理小的，因为大了会导致一些换行
        style['letter-spacing'] = (val?.val / 100 - 1) + 'em'
      }

    } else if (key === 'ind') {
      var indStyle = getIndentStyle(val)
      Object.assign(style, indStyle)
    } else if (key === 'szCs' || key === 'bCs' || key === 'iSz') {
      // skip 这几个属性目前认为没用，对复杂字符生效
    } else if (_.endsWith(key, 'paraId') || _.endsWith(key, 'textId')) {
      // skip
    } else if (_.includes(key, 'rsidR') || _.includes(key, 'rsidP')) {
      // skip
    } else if (key === 'rPr') {
      // 整段通用字体样式
      if (val.vanish) {
        style['display'] = 'none'
      }
    } else if (key === 'color') {
      style['color'] = val.val === 'auto' ? '#000' : '#' + val.val
    } else if (key === 'highlight') {
      let item = _.find(highlightPool, {
        name: val.val
      })
      if (item) {
        style['background-color'] = item.value
      } else {
        style['background-color'] = val.val
      }
    } else if (key === 'vertAlign') {
      if (val.val === 'superscript') {
        style['vertical-align'] = 'super'
        style['font-size'] = '12px'
      } else if (val.val === 'subscript') {
        style['vertical-align'] = 'sub'
        style['font-size'] = '12px'
      }
    } else if (key === 'pStyle') {
      // skip
    } else if (key === 'numPr') {
      // 统一在 num 组件中处理
    } else if (key === 'tabs') {
      // 属性作用未知
    } else if (key === 'lang') {
      // 先只处理中文
    } else if (key === 'widowControl') {
      // 属性作用未知
    } else if (key === 'sectPr') {
      // 属性作用未知
    } else if (key === 'name') {
      // skip
    } else if (key === 'vanish') {
      style['display'] = 'none'
    } else {
      // console.log('unknown prop', key, JSON.stringify(val, 0, 4), JSON.stringify(this.content))
      // console.log('unknown prop', key)
    }
  }

  if (props.spacing) {
    var spacingStyle = getSpacingStyle(props.spacing)
    Object.assign(style, spacingStyle)
  }

  return style
}

export function findTextNode(element) {
  if (!element) return
  if (element.nodeType === 3) {
    return element
  }
  if (element.childNodes) {
    for (let node of element.childNodes) {
      var textNode = findTextNode(node)
      if (textNode) {
        return textNode
      }
    }
  }
}

export function isPropFalse(prop) {
  if (prop?.val) {
    prop = prop.val
  }
  if (prop === '0' || prop === 'none' || prop === 'false') {
    return true
  }
  return false
}

export function normalizeFontFamily(str) {
  if (!str) return
  str = str.split(';')[0] // 兼容之前的污染文件或者css格式
  str = str.split(/\sSC$/i).join('')
  var map = {
    'songti': '宋体',
    '楷体_gb2312': '楷体',
    '仿宋_gb2312': '仿宋',
    '宋体_gb2312': '宋体'
  }
  str = map[str.toLowerCase()] || str
  var arr = [str]
  if (str.length <= 2 && isCn(str)) {
    arr.push(str + '-简', '华文' + str)
  }
  if (str === '微软雅黑') {
    // arr.push('冬青黑体简体中文')
    arr.push('PingFang SC')
  } else if (str === '苹方') {
    arr.push('微软雅黑')
  }
  var ret = arr.map(item => `"` + item + `"`).join(',')
  return ret
}

export function isCn(str) {
  return /^[\u4e00-\u9fa5]+$/i.test(str)
}

export function normalizeColor(color) {
  if (!color) {
    return
  }
  color = color.split(/\s+/)[0] // 空格后面的不要
  if (color === 'auto') {
    return
  }
  if (/^[0-9A-F]{6}$/i.test(color)) {
    return '#' + color
  }
  return color
}

export function translateNum(num, translateString) {
  translateString = translateString || '零一二三四五六七八九十百千'
  var arr = String(num).split('')
  return arr.map((item, i) => {
    var unit = ''
    var unitIndex = arr.length - i - 1
    unit = unitIndex ? translateString[10 + unitIndex - 1] : ''
    if (item == 0) {
      return ''
    }
    if (unitIndex == 1 && item == 1) {
      // 一十应该返回十
      return unit
    }
    return translateString[item] + unit
  }).join('')
}

export function getIndentStyle(indent) {
  // 处理段落-缩进样式
  if (!indent) {
    return
  }
  var style = {}
  var firstIndent = 0 // 首行缩进
  var nextIndent = 0 // 第二行即之后缩进
  var left = Number(indent['left']) || 0
  var leftChars = Number(indent['leftChars']) || 0
  var hanging = Number(indent['hanging']) || 0
  var firstLine = Number(indent['firstLine']) || 0
  var firstLineChars = Number(indent['firstLineChars']) || 0
  if (hanging) {
    // 悬挂缩进 hanging 最优先
    firstIndent = left - hanging
    nextIndent = left
  } else if (firstLineChars) {
    // 首行缩进字符优先
    style['text-indent'] = (firstLineChars / 100) + 'em'
  } else if (firstLine) {
    // 首行缩进
    firstIndent = left + firstLine
    nextIndent = left
  } else if (leftChars) {
    style['padding-left'] = (leftChars / 100) + 'em'
  } else if (left) {
    firstIndent = left
    nextIndent = left
  }

  if (!style['padding-left']) {
    style['padding-left'] = dxa2css(nextIndent)
  }

  if (!style['text-indent']) {
    style['text-indent'] = dxa2css(firstIndent - nextIndent)
  }

  // 文本之后
  if (indent['rightChars']) {
    style['padding-right'] = indent['rightChars'] / 100 + 'em'
  } else if (indent['right']) {
    style['padding-right'] = dxa2css(indent['right'])
  }

  return style
}

function getSpacingStyle(spacing) {
  // spacing 属于段落属性
  var ret = {}
  if (spacing['before']) {
    ret['padding-top'] = dxa2css(spacing['before'])
  }
  if (spacing['after']) {
    ret['padding-bottom'] = dxa2css(spacing['after'])
  }
  return ret
}

export function pt2px(val) {
  return val * 4 / 3 + 'px'
}

export function emu2css(val) {
  return val / 914400 * 72 + 'pt'
}

export function dxa2css(val) {
  if (val) {
    return val / 20 + 'pt'
  }
  return 0
}

export function dxa2px(val) {
  // 有些地方只能 px，比如表格 grid
  return val / 20 * 4 / 3 + 'px'
}

export function str2entity(str) {
  return str.replace(/./gm, function(s) {
    return (s.match(/[a-z0-9\s]+/i)) ? s : '&#' + s.charCodeAt(0) + ';'
  })
}

export function isEmptyRange(range) {
  var { start, end } = range
  if (start.offset === end.offset) {
    if (Path.compare(start.path, end.path) === 0) {
      return true
    }
  }
  return false
}

export const sharedData = {
  numState: {}
}
