<template>
  <!-- 不太好看，先隐藏，而且会影响段落缩进的呈现 -->
  <div class="word-page-break" v-show="false" :style="style">分页符</div>
</template>

<script>
import WordMixin from '@/word/word-mixin'

// 在无法预计算行渲染前，用脏实现解决绝对定位的文本框和分节符分页内容重合的 bug
export default {
  mixins: [WordMixin],
  props: ['id'],
  data() {
    return {
      rawOffsetTop: 0
    }
  },
  computed: {
    style() {
      // 目前没用了
      var bottomMap = this.getApiInstance().docStore.bottomMap
      var mostBottom = 0
      for (var key in bottomMap) {
        if (key < this.id) {
          // 说明在前面渲染
          mostBottom = Math.max(mostBottom, bottomMap[key])
        }
      }
      if (this.rawOffsetTop < mostBottom) {
        return {
          'padding-top': (mostBottom - this.rawOffsetTop) + 'px'
        }
      }
      return null
    }
  },
  mounted() {
    this.rawOffsetTop = this.$el.offsetTop
  }
}
</script>

