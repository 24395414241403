import DocStore from '@/components/docstore'
import Vue from 'vue'
import _ from 'lodash'
import Comments from '@/comments'
import * as utils from '@/word/utils'
import Numbering from '@/numbering'
import * as Path from '@/path'
import History from '@/history'
// Editor 仅在前端运行, 未来可以把数据运算放入另一个文件

const DEFAULT_PAGE_SIZE = {
  width: 612 * 20,
  height: 792 * 20,
  contentHeight: 648 * 20,
  contentWidth: 432 * 20,
  left: 90 * 20,
  top: 72 * 20,
  right: 90 * 20,
  bottom: 72 * 20
}

const TYPE_COMPACT_POWEROFFICE = {
  'w:r': 'ParaRun',
  'w:p': 'Paragraph',
  'w:body': 'CDocument',
  'w:bookmarkEnd': 'CParagraphBookmark',
  'w:bookmarkStart': 'CParagraphBookmark',
  'w:tbl': 'CTable'
}

class Editor {
  // 方法可参考: https://rain120.github.io/athena/zh/slate/api/nodes.html#editor
  constructor(opt) {
    opt = opt || {}
    this.events = opt.events // 专门处理事件
    this.root = opt.root
    this.selection = null
    this.key2pathMap = {}
    this.version = VERSION
    this.buildTime = BUILD_TIME
    this.blob = null
    this.config = null
    this.zip = null
    this.comments = new Comments(this)
    this.numbering = new Numbering(this)
    this.docStore = DocStore(this)
    this.history = new History(this)
  }

  escapeCommentText(text) {
    text = _.toString(text)
    return text.split(/<\w+ .*<\/\w+>/).join('')
  }

  scrollToId(id) {
    var el = this.root.querySelector(`[data-key="${id}"]`)
    if (el) {
      el.scrollIntoView()
    }
  }

  getOffset(el, root) {
    root = root || this.root
    var offset = {
      top: 0,
      left: 0
    }
    while (el) {
      offset.top += el.offsetTop
      offset.left += el.offsetLeft
      if (el.offsetParent === root) {
        break
      }
      el = el.offsetParent
    }
    return offset
  }

  getTOC() {
    // 获取目录
    // 先获取大纲级别
    var headingIdMap = this.docStore.styleHeadingIdMap
    // 遍历全部 body
    var toc = []
    var body = this.getElementByNamePath('body')
    if (body?.children) {
      body.children.forEach(node => {
        if (node.name === 'w:p') {
          var pStyleId = this.getElementByNamePath('pPr.pStyle.val', node)
          if (pStyleId) {
            var level = headingIdMap[pStyleId]
            if (level) {
              var text = utils.getText(node)
              if (node.numStr) {
                text = node.numStr + ' ' + text
              }
              toc.push({
                level,
                text,
                id: node.id
              })
            }
          }
        }
      })
    }
    return toc
  }

  async handleAction(fn) {
    var ret
    if (typeof fn === 'function') {
      this.history.belongOneOpList(async () => {
        ret = await fn()
      })
    }
    this.comments.updateStatus()
    this.docStore.page = this.getPage()
    return ret
  }

  emit(...args) {
    return this.events.$emit(...args)
  }

  format(element, opt) {
    element = element || PowerOffice.editor.getElementByNamePath('body')
    var ret = {}
    if (element.name === 'w:r') {
      ret.text = this.getText(element)
      ret.size = ret.text.length
      ret.reviewType = 'normal'
    } else if (element.name === 'w:del') {
      ret.text = this.getText(element.children?.[0])
      ret.size = ret.text.length
      ret.reviewType = 'delete'
      ret.name = 'w:r'
    } else if (element.name === 'w:ins') {
      ret.text = this.getText(element.children?.[0])
      ret.size = ret.text.length
      ret.reviewType = 'add'
      ret.name = 'w:r'
    } else if (element.children) {
      ret.reviewType = 'normal'
      if (element.name === 'w:p') {
        var rPr = this.getElementByNamePath('pPr.rPr', element)
        var del = this.getElementByNamePath('del', rPr)
        if (del) {
          ret.reviewType = 'delete'
        } else {
          var ins = this.getElementByNamePath('ins', rPr)
          if (ins) {
            ret.reviewType = 'add'
          }
        }
      }
      ret.content = element.children.map(item => {
        if (!item.name.endsWith('Pr') && !item.name.endsWith('tblGrid') && !item.name.endsWith('PrEx')) {
          return this.format(item, opt)
        }
      }).filter(item => item)
      if (element.name === 'w:body') {
        // 最外层仅支持三种类型
        ret.content = ret.content.filter(item => {
          let name = utils.removeNS(item.name)
          return utils.FIRST_MAIN_TAGS.includes(name)
        })
      }
      ret.size = ret.content.length
    }

    if (!ret.name) {
      ret.name = element.name
    }
    ret.type = TYPE_COMPACT_POWEROFFICE[ret.name]
    return ret
  }

  getText(node) {
    return utils.getText(node)
  }

  async insertText(text, point) {
    var { path, offset } = point || this.getCurrentPoint()
    this.history.pushOp({
      type: 'insert_text',
      path,
      offset,
      text
    })
    console.log('insert', path, offset, text)
    var element = this.getElementByPath(path)
    if (element.name === 'w:p') {
      // 空段落
      element.children = [{
        name: 'w:r',
        id: _.uniqueId(),
        children: [{
          name: 'w:t',
          id: _.uniqueId(),
          attrs: {
            'xml:space': 'preserve'
          },
          text: ''
        }]
      }]
      element = element.children[0].children[0]
    }
    element.text = element.text.slice(0, offset) + text + element.text.slice(offset)
    offset += text.length
    await this.updateCursor(element.id, offset)
  }

  deleteTextInElement(element, start, end) {
    var editor = this
    start = start || 0
    end = end || Infinity
    var tNode = element
    if (tNode.name === 'w:r') {
      var tNode = editor.getElementByNamePath('t', tNode)
    }
    if (tNode && tNode.text != null) {
      tNode.text = tNode.text.slice(0, start) + tNode.text.slice(end, Infinity)
    }
  }

  async replaceRange(range, text) {
    await this.deleteRange(range)
    await this.insertText(text, range.start)
  }

  async deleteRange(range) {
    var editor = this
    var needRemove = []
    var start = Object.assign({}, range.start)
    var end = Object.assign({}, range.end)
    var active = false
    var startPath = start.path
    if (startPath && startPath.length > 2) {
      startPath = startPath.slice(0, -1)
    }
    var commentsToDelete = []

    this.nodes({
      from: startPath,
      pass: (path, node) => {
        if ((end.key && node.id === end.key) || Path.equal(path, end.path)) {
          end.path = path
          end.key = node.id
          let startOffset
          if ((start.key && node.id === start.key) || Path.equal(path, start.path)) {
            startOffset = start.offset
          }
          this.deleteTextInElement(node, startOffset, range.end.offset)
          active = false
          return true
        }

        if (active) {
          let name = utils.removeNS(node.name)
          if (utils.CONTENT_TAGS.includes(name)) {
            // 仅删除内容标签，需要保留属性标签
            needRemove.push(path)
          } else if (name.startsWith('comment')) {
            let commentId = node.attrs['w:id']
            commentsToDelete.push(commentId)
          }
        }

        if ((start.key && node.id === start.key) || Path.equal(path, start.path)) {
          start.path = path
          start.key = node.id
          this.deleteTextInElement(node, start.offset)
          active = true
        }
      }
    })

    for (let path of needRemove.reverse()) {
      if (Path.containOrEqual(path, start.path) || Path.containOrEqual(path, end.path)) {
        continue
      }
      editor.history.pushOp({
        type: 'remove_node',
        path,
        node: editor.getElementByPath(path)
      })
      editor.removeElement(path)
    }

    // 注意，word并不会merge两个段落，wps会，此处和word保持一致
    await this.updateCursor(start.key, start.offset) // 始终定位到 start
    this.clearSelectionCache()

    if (commentsToDelete.length) {
      this.comments.deleteBatch(commentsToDelete)
    }
  }

  clearSelection() {
    this.clearSelectionCache()
    var sel = window.getSelection()
    if (sel) {
      sel.empty()
    }
  }

  generateParaId() {
    // 8位数字+大写字母, 必须数字开头
    var demoId = '7C763CCF'
    var ret = _.random(0, 9) + Math.random().toString(16).substring(2, demoId.length - 1 + 2)
    ret = ret.toUpperCase()
    return ret
  }

  createParas(text) {
    var arr = text.split('\n')
    arr = arr.map(text => {
      return {
        name: 'w:p',
        id: _.uniqueId(),
        attrs: {
          'w14:paraId': this.generateParaId()
        },
        children: [{
          name: 'w:r',
          id: _.uniqueId(),
          children: [{
            name: 'w:t',
            id: _.uniqueId(),
            attrs: {
              'xml:space': 'preserve'
            },
            text
          }]
        }]
      }
    })
    return arr
  }

  removeNS(str) {
    if (!str) {
      debugger
    }
    return str.split(':').slice(-1)[0]
  }

  rawPr2dict(pr) {
    var dict = {}
    if (!pr) {
      return dict
    }
    _.each(pr.children, item => {
      dict[this.removeNS(item.name)] = this.rawPr2dict(item)
    })
    _.each(pr.attrs, (val, key) => {
      dict[this.removeNS(key)] = val
    })
    return dict
  }

  getElementByNamePath(namePath, element) {
    // e.g. 'body.sectPr'
    // 自动匹配 children tag 或者 attr key
    element = element || this.getDocument() // document 自己
    if (!element) {
      return null
    }
    if (typeof namePath === 'string') {
      namePath = namePath.split('.')
    }
    for (var i = 0; i < namePath.length; i++) {
      var key = namePath[i]
      let matched
      matched = _.find(element.children, item => {
        if (item.name) {
          var tagName = _.last(item.name.split(':'))
          if (tagName === key) {
            return true
          }
        }
        return false
      })
      if (!matched) {
        matched = _.find(element.attrs, (val, subKey) => {
          subKey = _.last(subKey.split(':'))
          if (subKey === key) {
            return true
          }
          return false
        })
      }
      if (!matched) {
        return null
      }
      element = matched
    }
    return element
  }

  getDocument() {
    return this.docStore.document?.[0]
  }

  async insertBreak() {
    // 回车换段
    var point = this.getCurrentPoint()
    let newPara = this.splitPara(point)
    let newRun = this.getElementByNamePath('r', newPara)
    this.updateCursor(newRun.id, 0)
    this.handleAction() // 触发重排序号
  }

  nodes(opt) {
    // 遍历全部节点是很快的, 毫秒级别的, 消耗性能的主要都是 vue 依赖更新和 dom 更新
    // slate 的 nodes 是一个生成器, 参考 https://github.com/ianstormtaylor/slate/blob/main/packages/slate/src/interfaces/node.ts#L479
    const {
      from,
      reverse = false,
      pass
    } = opt
    var path = from || [0]
    var visited = new Set()
    var node
    if (from) {
      node = this.getElementByPath(from)
    }
    while (true) {
      if (path.length === 0) {
        break
      }

      if (node && pass) {
        if (!visited.has(path.join('-'))) {
          if (pass(path, node)) {
            return path
          }
        }
      }

      node = node || this.getElementByPath(path)

      // 先找儿子
      if (node.children && node.children.length && !visited.has(path.join('-'))) {
        visited.has(path.join('-'))
        var childIndex = reverse ? node.children.length - 1 : 0
        path = path.concat(childIndex) // 必然存在 node
      } else {
        // 找不到儿子找兄弟
        var siblingPath = path.slice(0, -1).concat(path.slice(-1)[0] + (reverse ? -1 : 1))
        var siblingElement = this.getElementByPath(siblingPath)
        if (siblingElement) {
          path = siblingPath
        } else {
          // 找不到兄弟就找爸爸
          path = path.slice(0, -1)
          visited.add(path.join('-'))
        }
      }
      node = this.getElementByPath(path)
    }
  }

  async deleteForward() {
    // Backspace
    if (this.selection) {
      await this.handleAction(async () => {
        await this.deleteRange(this.getSelectKeyRange({ tagName: 'r' }))
      })
      return
    } else {
      await this.deleteForwardByPoint(this.getCurrentPoint())
    }
  }

  async deleteForwardByPoint(point) {
    // TODO 删到回车需要单独删一次回车并合并段落
    var { path, offset } = point
    if (offset === 0) {
      var prevPoint = this.getPrevPoint(point)
      if (!prevPoint) {
        return
      }
      var paraPath = path.slice(0, -2)
      var prevParaPath = prevPoint.path.slice(0, -2)
      if (Path.equal(paraPath, prevParaPath)) {
        return this.deleteForwardByPoint(prevPoint)
      } else {
        // 向上合并段落
        this.mergePara(prevParaPath, paraPath)
        await this.updateCursor(prevPoint.key, prevPoint.offset)
        return
      }
    }

    console.log('delete forward', path, offset)
    var element = this.getElementByPath(path)
    if (offset >= 1) {
      var deletedText = element.text.slice(offset - 1, offset)
      element.text = element.text.slice(0, offset - 1) + element.text.slice(offset)
      offset -= 1
      this.history.pushOp({
        type: 'remove_text',
        path,
        offset,
        text: deletedText
      })
      await this.updateCursor(prevPoint?.key || element.id, offset)
    }
  }

  mergePara(paraPathA, paraPathB) {
    // 使用 paraPathA 的 pPr
    var paraA = this.getElementByPath(paraPathA)
    var paraB = this.getElementByPath(paraPathB)
    var paraBElements = paraB.children.filter(item => item.name !== 'w:pPr')
    paraA.children.push(...paraBElements)
    this.removeElement(paraPathB)
  }

  splitPara(point) {
    let runPath = point.path.slice(0, -1)
    let paraPath = point.path.slice(0, -2)
    let parentPath = point.path.slice(0, -3)
    let parentElement = this.getElementByPath(parentPath)
    let para = this.getElementByPath(paraPath)
    let paraPr = this.getElementByNamePath('pPr', para)
    let run = this.getElementByPath(runPath)
    let newParas = this.createParas('')
    let newPara = newParas[0]
    newPara.children = [_.cloneDeep(paraPr)]
    let runIndex = _.last(runPath)
    let splited = this.splitRun(run, point.offset)
    newPara.children.push(splited[1])
    for (let i = runIndex + 1; i < para.children.length; i++) {
      newPara.children.push(para.children[i])
    }
    para.children = para.children.slice(0, runIndex + 1)
    parentElement.children.splice(_.last(paraPath) + 1, 0, newPara)
    return newPara
  }

  getRange(startPara, startChar, endPara, endChar) {
    startChar = startChar || 0
    if (endChar == null) {
      endChar = Infinity
    }
    startPara = this.coordinates2path(startPara)
    startPara = this.convertBizPath2XmlPath(startPara)
    endPara = this.coordinates2path(endPara)
    endPara = this.convertBizPath2XmlPath(endPara)
    var start = this.paraChar2Point([startPara, startChar])
    var end = this.paraChar2Point([endPara, endChar])
    if (!end.path || !start.path) {
      return null
    }
    return {
      start,
      end
    }
  }

  coordinates2path(str) {
    if (typeof str === 'number') {
      return [str]
    }
    if (typeof str === 'string') {
      return str.split('-').map(item => Number(item))
    }
    return str
  }

  paraChar2Point([paraPath, charIndex]) {
    charIndex = charIndex || 0 // 默认是0
    var lastPath
    var lastTextNode
    this.nodes({
      from: paraPath,
      pass: (path, node) => {
        if (!this.containsPath(paraPath, path)) {
          if (lastTextNode) {
            charIndex = lastTextNode.text.length
          } else {
            lastPath = null
          }
          return true
        }
        if (node.text && node.name === 'w:t') {
          // 必须得是 <w:t> 标签，<w:instrText> 也有 text，但不能计数
          lastPath = path
          lastTextNode = node
          if (charIndex <= node.text.length) {
            return true
          }
          charIndex -= node.text.length
        } else if (node.name === 'w:tab') {
          // tab 占一个位置
          let parent = this.getElementByPath(path.slice(0, -1))
          // 注意属性也叫 <w:tab> 因此只有正文的才算
          if (parent.name === 'w:r') {
            charIndex -= 1
          }
        } else if (node.name === 'w:br') {
          // br 算一个空格
          charIndex -= 1
        }
      }
    })
    if (!lastPath) {
      var para = this.getElementByPath(paraPath)
      if (para) {
        // 匹配到空段落了，但也是正常的 range
        lastPath = paraPath
        charIndex = 0
      }
    }
    return {
      path: lastPath,
      offset: charIndex
    }
  }

  containsPath(parentPath, childPath) {
    // e.g. parentPath: [0], childPath: [0, 0]
    for (let i = 0; i < parentPath.length; i++) {
      if (childPath[i] != parentPath[i]) {
        return false
      }
    }
    return true
  }

  getPrevPoint(point) {
    // 此处仅适用 offset = 0
    var { path } = point
    var prevPath = this.nodes({
      from: path,
      reverse: true,
      pass(currentPath, node) {
        if (Path.equal(path, currentPath)) {
          return
        }
        if (node.text) {
          return true
        }
      }
    })
    if (!prevPath) {
      return null
    }
    var prevElement = this.getElementByPath(prevPath)
    return {
      path: prevPath,
      offset: prevElement.text.length,
      key: prevElement.id
    }
  }

  async deleteBackward() {
    // Delete
    if (this.selection) {
      await this.handleAction(() => {
        return this.deleteRange(this.getSelectKeyRange({ tagName: 'r' }))
      })
      return
    }
    this.deleteBackwardByPoint(this.getCurrentPoint())
  }

  async deleteBackwardByPoint(point, text) {
    var size = 1
    if (text != null) {
      size = text.length
    }
    point = point || this.getCurrentPoint()
    var { path, offset } = point
    var element = this.getElementByPath(path)
    var deletedText = element.text.slice(offset, offset + size)
    element.text = element.text.slice(0, offset) + element.text.slice(offset + size)
    this.history.pushOp({
      type: 'remove_text',
      path,
      offset,
      text: deletedText
    })
    await this.updateCursor(element.id, offset)
  }

  async updateCursor(key, offset) {
    await this.nextTick()
    var textElement = this.root.querySelector(`[data-key="${key}"]`)
    textElement.scrollIntoViewIfNeeded()
    var newTextNode = utils.findTextNode(textElement)
    if (newTextNode) {
      offset = Math.max(0, offset)
      offset = Math.min(offset, newTextNode.length)
      window.getSelection().setBaseAndExtent(newTextNode, offset, newTextNode, offset)
      this.clearSelectionCache()
    } else {
      // 空标签
      var tEl = textElement.querySelector('.word-t')
      if (tEl) {
        window.getSelection().setBaseAndExtent(tEl, 0, tEl, 0)
      }
    }
  }

  nextTick() {
    return Vue.nextTick()
  }

  delete() {

  }

  convertBizPath2XmlPath(bizPath) {
    // 把业务路径转为 xml 路径
    // 1. tblPr 这种属性需要跳过
    // 2. 段落级别的 bookmark 需要跳过
    var xmlPath = []
    var element = this.getElementByNamePath('body')
    for (let i = 0; i < bizPath.length; i++) {
      let bizIndex = bizPath[i]
      var xmlIndex = this.convertBizIndex2XmlIndex(element, bizIndex, i)
      xmlPath[i] = xmlIndex
      element = element.children[xmlIndex]
    }
    return xmlPath
  }

  convertBizIndex2XmlIndex(element, bizIndex, index) {
    // xml index 必然 >= biz index
    for (var i = 0; i < element.children.length; i++) {
      var child = element.children[i]
      var name = utils.removeNS(child.name)
      if (index === 0) {
        if (utils.FIRST_MAIN_TAGS.includes(name)) {
          // 第一层只认这三个
          bizIndex--
        }
      } else if (utils.SUPPORTED_TAGS.has(name)) {
        bizIndex--
      }
      if (bizIndex === -1) {
        return i
      }
    }
  }

  countVueDepWatcher() {
    // 检查 docStore 是否滥用依赖收集
    var data = this.docStore.$data
    for (var key in data) {
      var val = data[key]
      var depCount = val?.['__ob__']?.dep?.subs?.length
      if (depCount > 1000) {
        console.log('dep watcher leak', key, depCount)
      }
    }
  }

  getElementByPath(path) {
    // 主要在 nodes 中使用，必须保持高性能
    if (!path) {
      debugger
    }
    var element = this.getElementByNamePath('body')
    for (let i = 0; i < path.length; i++) {
      let index = path[i]
      if (index === Infinity) {
        element = element.children.slice(-1)[0]
      } else {
        if (!element.children) {
          debugger
        }
        element = element.children[index]
      }
    }
    return element
  }

  getCurrentPoint() {
    return this.getSelectRange().end
  }

  getSelectRange() {
    var keyRange = this.getSelectKeyRange()
    if (!keyRange) {
      return null
    }
    var ret = {
      start: {
        path: this.getPathByKey(keyRange.start.key), // <w:t> 标签
        offset: keyRange.start.offset
      },
      end: {
        path: this.getPathByKey(keyRange.end.key),
        offset: keyRange.end.offset
      },
      isValid: false
    }
    if (ret.start.path && ret.end.path) {
      ret.isValid = true
    }
    return ret
  }

  getSelectKeyRange(opt) {
    opt = opt || {
      tagName: 't'
    }
    var selection = this.selection || window.getSelection()
    if (!selection.focusNode) {
      return null
    }
    var position = selection.anchorNode.compareDocumentPosition(selection.focusNode)
    var backward = false
    if (!position && selection.anchorOffset > selection.focusOffset || position === Node.DOCUMENT_POSITION_PRECEDING) {
      backward = true
    }

    var startEl = this.getKeyElementByNode(selection.anchorNode, opt.tagName ? `.word-${opt.tagName}` : null)
    if (!startEl) {
      startEl = this.getKeyElementByNode(selection.anchorNode)
    }
    var start = {
      key: startEl.dataset.key,
      el: startEl,
      offset: selection.anchorOffset
    }

    var endEl = this.getKeyElementByNode(selection.focusNode, opt.tagName ? `.word-${opt.tagName}` : null)
    if (!endEl) {
      endEl = this.getKeyElementByNode(selection.focusNode)
    }
    var end = {
      key: endEl.dataset.key,
      el: endEl,
      offset: selection.focusOffset
    }

    if (backward) {
      [start, end] = [end, start]
    }

    return {
      start,
      end
    }
  }

  getKeyElementByNode(node, selector) {
    // selector 可以是 .word-t .word-r .word-p 等
    selector = selector || '[data-key]'
    let el = node
    if (el.nodeType === 3) {
      el = el.parentElement
    }
    // closest 的搜索也包括自己
    return el.closest(selector)
  }

  getPathByKey(key) {
    return this.key2pathMap[key]
  }

  getPathByDom(dom) {
    var el = this.getKeyElementByNode(dom)
    var key = el.dataset.key
    // 竟然不是很消耗性能
    return this.getPathByKey(key)
  }

  clearRunPr(run) {
    var rPr = _.find(run.children, item => item.name === 'w:rPr')
    if (rPr) {
      rPr.children = []
    }
  }

  setPr(el, props, path, clearAllProps) {
    // type 可以是 r p tbl
    var type = this.removeNS(el.name)
    var pr = _.find(el.children, item => item.name === `w:${type}Pr`)
    if (!pr) {
      pr = {
        name: `w:${type}Pr`,
        children: []
      }
      el.children.unshift(pr)
    }
    var oldProps = this.rawPr2dict(pr)
    this.history.pushOp({
      type: 'set_node',
      path,
      props,
      oldProps
    })
    if (props[`w:${type}Style`] || clearAllProps) {
      // 设置 rStyle 直接清空行内样式
      pr.children = []
    }
    for (var key in props) {
      var prop = _.find(pr.children, item => item.name === key)
      if (!prop) {
        pr.children.push({
          name: key,
          attrs: props[key]
        })
      } else {
        prop.attrs = props[key]
      }
    }
  }

  appendToPath(path, ...elements) {
    path = path.slice()
    var index = path.pop()
    var parentElement = this.getElementByPath(path)
    parentElement.children.splice(index + 1, 0, ...elements)
  }

  insertElement(point, ...elements) {
    var textElement = this.getElementByPath(point.path)
    var paraElement = null
    if (textElement.name === 'w:p') {
      paraElement = textElement
      textElement = null
    } else {
      paraElement = this.getElementByPath(point.path.slice(0, -2))
    }
    if (!textElement) {
      // 无文本就直接加入段落后面
      paraElement.children.push(...elements)
      return
    }
    var runIndex = point.path.slice(-2)[0]
    if (point.offset <= 0) {
      paraElement.children.splice(runIndex, 0, ...elements)
    } else if (point.offset >= textElement.text.length) {
      paraElement.children.splice(runIndex + 1, 0, ...elements)
    } else {
      var run = this.getRunElementByPath(point.path)
      var splitedRuns = this.splitRun(run, point.offset)
      paraElement.children.splice(runIndex, 1, ...splitedRuns)
      paraElement.children.splice(runIndex + 1, 0, ...elements)
    }
  }

  removeElement(path) {
    // 删除节点只需要路径，无需 point
    path = path.slice()
    var index = path.pop()
    var parentElement = this.getElementByPath(path)
    parentElement.children.splice(index, 1)
  }

  getRunElementByPath(path) {
    while (path && path.length) {
      var element = this.getElementByPath(path)
      if (element.name === 'w:r') {
        return element
      }
      path = path.slice(0, -1)
    }
  }

  splitRun(run, offset) {
    // 默认 run 下面只有一个 t
    var textEl = _.find(run.children, item => item.name === 'w:t')
    if (textEl) {
      var newRun = _.cloneDeep(run)
      var text = textEl.text || ''
      textEl.text = text.slice(0, offset)
      newRun.id = _.uniqueId()
      _.each(newRun.children, item => {
        if (item.name === 'w:t') {
          item.id = _.uniqueId() // 需要用新的 id
          item.text = text.slice(offset)
        }
      })
      return [run, newRun]
    }
  }

  cacheSelection() {
    var selection = window.getSelection()
    this.selection = {
      anchorNode: selection.anchorNode,
      focusNode: selection.focusNode,
      anchorOffset: selection.anchorOffset,
      focusOffset: selection.focusOffset
    }
  }

  clearSelectionCache() {
    this.selection = null
  }

  getSelectionTop() {
    var scrollElement = this.root.closest('.word-container').parentElement
    const domSelection = window.getSelection()
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    var top = rect.top + window.pageYOffset - 30 + scrollElement.scrollTop
    return top
  }

  getPage() {
    var sectPr = this.sectPr
    var getEl = (namePath) => {
      return this.getElementByNamePath(namePath, sectPr)
    }

    var ret = {
      width: getEl('pgSz.w'),
      height: getEl('pgSz.h'),
      contentHeight: (getEl('pgSz.h') - getEl('pgMar.top') - getEl('pgMar.bottom')),
      contentWidth: (getEl('pgSz.w') - getEl('pgMar.left') - getEl('pgMar.right')),
      left: getEl('pgMar.left'),
      top: getEl('pgMar.top'),
      right: getEl('pgMar.right'),
      bottom: getEl('pgMar.bottom')
    }

    // 不是每个文件都有 sectPr，加上 word 用的默认值
    for (var key in ret) {
      if (ret[key] == null || isNaN(ret[key])) {
        ret[key] = DEFAULT_PAGE_SIZE[key]
      }
      ret[key] = ret[key] / 20
    }

    return ret
  }

  getLineHeight(fontHeight, spacing) {
    // fontHeight = fontSize, 单位是 pt
    // 多倍行距, 受网格对齐(snaptogrid)影响
    if (!fontHeight || !spacing) {
      return
    }
    var ret = {}
    var lineRule = spacing['lineRule']
    if (lineRule === 'exact') {
      // 固定值
      ret['line-height'] = utils.dxa2css(spacing['line'])
      ret['min-height'] = ret['line-height']
    } else if (lineRule === 'atLeast') {
      // 最小值
      ret['line-height'] = (Math.max(spacing['line'] / 20, fontHeight * 1.297)) + 'pt'
      ret['min-height'] = ret['line-height']
    } else if (lineRule === 'auto') {
       // font size 是行内属性
      var minLineHeight = fontHeight * 1.297
      var linePitch = this.getElementByNamePath('docGrid.linePitch', this.sectPr)
      var gridHeight = linePitch / 20
      var lineHeightWithoutGrid = fontHeight * spacing['line'] / 240 * 1.297

      if (gridHeight && lineHeightWithoutGrid) {
        var gridCount = 1
        while (true) {
          if (gridHeight * gridCount > minLineHeight) {
            break
          }
          gridCount++
        }
        var lineHeightWithGrid = gridHeight * gridCount
        var lineHeight = Math.max(lineHeightWithoutGrid, lineHeightWithGrid) // 用大的那个
        ret['line-height'] = lineHeight + 'pt'
      } else {
        ret['line-height'] = lineHeightWithoutGrid + 'pt'
      }
    }
    return ret
  }
}

export default Editor
