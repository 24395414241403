export function containOrEqual(pathA, pathB) {
  // 相等或父子关系
  if (!pathA || !pathB) {
    return false
  }
  var len = Math.min(pathA.length, pathB.length)
  for (var i = 0; i < len; i++) {
    var valA = pathA[i] || 0
    var valB = pathB[i] || 0
    if (valA !== valB) {
      return false
    }
  }
  return true
}

export function equal(pathA, pathB) {
  if (!pathA || !pathB) {
    return false
  }
  if (pathA.length === pathB.length && compare(pathA, pathB) === 0) {
    return true
  }
  return false
}

export function compare(pathA, pathB) {
  if (!pathA || !pathB) {
    return false
  }
  var len = Math.max(pathA.length, pathB.length)
  for (var i = 0; i < len; i++) {
    var valA = pathA[i] || 0
    var valB = pathB[i] || 0
    if (valA > valB) {
      return 1
    } else if (valA < valB) {
      return -1
    }
  }
  return 0
}

export function next(path) {
  return [...path.slice(0, -1), path.slice(-1)[0] + 1]
}
