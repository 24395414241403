
<template>
  <div class="word-object">
    <img
      v-if="element.shape"
      :src="imageInfo.url"
      :title="imageInfo.name"
      :style="element.shape.style">
    <slot v-else></slot>
  </div>
</template>
<script>
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    imageInfo() {
      var rId = this.element.shape.imagedata.id
      var file = this.getApiInstance().docStore.relationships[rId]
      return {
        url: URL.createObjectURL(file.content),
        name: file.target
      }
    }
  }
}
</script>
