<template>
  <span class="word-num" contenteditable="false" :style="numStyle">{{ numStr }}<span class="word-suff" v-if="suff === 'space'" v-html="' '"></span><span class="word-suff" :style="suffStyle" v-else-if="suff === 'tab'" v-html="'\t'"></span></span>
</template>
<script>
import * as utils from '@/word/utils'

export default {
  props: {
    numPr: Object,
    numStr: String
  },
  inject: ['getApiInstance'],
  computed: {
    suff() {
      if (!this.numInfo) {
        return
      }
      var suff = this.numInfo.suff?.val || 'tab'
      return suff
    },
    numInfo() {
      return this.getApiInstance().numbering.getNumInfo(this.numPr)
    },
    suffStyle() {
      if (this.suff === 'tab') {
        var defaultTabStop = this.getApiInstance().docStore.defaultTabStop
        if (defaultTabStop === '0pt') {
          // 仅处理0的情况
          return {
            'min-width': 0
          }
        }
      }
    },
    numStyle() {
      var paraStyle = utils.props2style(this.$parent.$parent.element?.pPr?.rPr)
      var numStyle = utils.props2style(this.numInfo?.rPr)
      var style = Object.assign({}, numStyle, paraStyle) // para style 覆盖 num style
      return style
    }
  }
}
</script>
