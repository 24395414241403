<template>
  <span class="word-r" @click="handleClick" :style="style" :class="rClass">
    <word-num v-if="element.rPr && element.rPr.numPr" :numPr="element.rPr.numPr"></word-num>
    <slot></slot>
  </span>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import * as utils from '@/word/utils.js'
import _ from 'lodash'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    text() {
      return utils.getText(this.element)
    },
    rPr() {
      var editor = this.getApiInstance()
      var rPr = editor.getElementByNamePath('rPr', this.element)
      rPr = editor.rawPr2dict(rPr)
      return rPr
    },
    rStyleId() {
      var editor = this.getApiInstance()
      return editor.getElementByNamePath('rPr.rStyle.val', this.element)
    },
    rClass() {
      // class 和 行内属性谁优先?
      var ret = {}
      if (this.rStyleId != null) {
        ret[`word-style-${this.rStyleId}`] = true
      }
      if (this.element.children) {
        var isTabRun = this.element.children.some(item => item.name === 'w:tab')
        if (isTabRun) {
          ret['word-toc-tab-wrapper'] = true
        }
      }
      return ret
    },
    style() {
      // 绝对定位的文本框要提前绝对定位，防止段落的 text-indent 失效
      // 会引发新的问题，先放弃
      // var isSpecialRun = _.every(this.element.children, item => {
      //   if (item.name === 'mc:AlternateContent' || item.name === 'w:rPr') {
      //     return true
      //   }
      //   return false
      // })
      // if (isSpecialRun) {
      //   return {
      //     'position': 'absolute',
      //     'left': '0'
      //   }
      // }
      var props = this.rPr
      var style = utils.props2style(props) || {}
      // 批注效果优先，和wps保持一致，其次是本身效果
      style['border-bottom'] = this.element.borderBottom || style['border-bottom']
      style['background-color'] = this.element.backgroundColor || style['background-color']
      return style
    }
  },
  methods: {
    getComments() {
      var allCommentsMap = this.getApiInstance().comments.getAllMap()
      var commentIdList = this.element.comments || []
      var comments = commentIdList.map(commentId => allCommentsMap[commentId]).filter(item => item && item.hidden !== true)
      return comments
    },
    handleClick(ev) {
      var editor = this.getApiInstance()
      var comments = this.getComments() // TODO 批注相关的先禁用
      var docStore = editor.docStore
      if (comments.length) {
        ev.stopPropagation()
        var selector = comments.map(item => {
          return `[data-comment-id="${item.id}"]`
        }).join(',')
        var commentEl = editor.root.querySelector(selector)
        var offset = editor.getOffset(commentEl)
        docStore.selectedCommentId = comments[0].id
        editor.comments.updateStatus()
        docStore.activeComment = {
          ...comments[0],
          commentId: comments[0].id,
          top: offset.top
        }
        setTimeout(() => {
          editor.emit('comment', {
            type: 'click',
            data: {
              comments
            }
          })
        }, 0)
      }
    }
  }
}
</script>
