<template>
  <div class="alternate-content">
    <word-element v-if="finalElement" :element="finalElement"></word-element>
  </div>
</template>

<script>
export default {
  props: {
    element: Object
  },
  components: {
    WordElement: () => import('@/word/word-element.vue')
  },
  inject: ['getApiInstance'],
  computed: {
    finalElement() {
      var editor = this.getApiInstance()
      // debugger
      var choice = editor.getElementByNamePath('Choice', this.element)
      var requires = choice.attrs.Requires
      if (requires === 'wpg') {
        // 普通图片
        var ret = choice.children[0]
      } else {
        // 大概率是文本框
        var pict = editor.getElementByNamePath('Fallback.pict', this.element)
        if (pict) {
          var ret = editor.getElementByNamePath('shape', pict) || editor.getElementByNamePath('rect', pict)
        }
      }
      return ret
    }
  }
}
</script>
