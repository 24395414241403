<template>
  <div
    v-if="page"
    class="word-page"
    ref="page"
    :style="style"
    @click="handleClick">
    <slot></slot>
    <div class="word-corners">
      <div
        v-for="(item, i) in pageCorners"
        :key="i"
        :style="item"
        class="word-corner"></div>
    </div>
    <div class="word-body" v-if="element">
      <word-element
        v-for="item in lazyShowList"
        :key="item.id"
        :element="item"></word-element>
    </div>
    <!-- <div
      class="word-page-sep"
      v-for="(item, i) in pageCount"
      :style="{ top: (item * page.contentHeight + page.top) + 'pt' }"
      :key="i">
      <span>页码{{item}}</span>
    </div> -->
  </div>
</template>
<script>
import WordMixin from '@/word/word-mixin'
import * as utils from '@/word/utils'
import WordElement from '@/word/word-element.vue'

export default {
  components: {
    WordElement
  },
  mixins: [WordMixin],
  props: {
    element: Object // 此 element 是 body
  },
  watch: {
    element: {
      handler() {
        this.renderCount = 30
        this.tryLazyLoad()
      },
      immediate: true
    }
  },
  data() {
    return {
      renderCount: 30 // 预渲染30个child
    }
  },
  computed: {
    pageCorners() {
      var size = 25 / 4 * 3
      var page = this.page
      // 顺时针
      var ret = [{
        // 左上
        pos: ['right', 'bottom']
      }, {
        // 右上
        pos: ['left', 'bottom']
      }, {
        // 左下
        pos: ['right', 'top']
      }, {
        // 右下
        pos: ['left', 'top']
      }]
      ret = ret.map(item => {
        var ret = {
          position: 'absolute',
          width: size + 'pt',
          height: size + 'pt'
        }
        item.pos.forEach(item => {
          if (item === 'right') {
            ret.left = (page.left - size) + 'pt'
          }
          if (item === 'left') {
            ret.right = (page.right - size) + 'pt'
          }
          if (item === 'bottom') {
            ret.top = (page.top - size) + 'pt'
          }
          if (item === 'top') {
            ret.bottom = (page.bottom - size) + 'pt'
          }
          ret[`border-${item}`] = '1px solid #ccc'
        })
        return ret
      })
      return ret
    },
    showList() {
      return utils.filterAndIndexElements(this.element.children)
    },
    lazyShowList() {
      // return this.element.children
      if (this.renderCount) {
        return this.showList.slice(0, this.renderCount)
      }
      return this.showList // TODO 触发大量重绘
    },
    items() {
      return utils.filterAndIndexElements(this.element.children)
    },
    finalDocHeight() {
      var singleContentHeight = this.page.contentHeight
      return singleContentHeight * this.pageCount + this.page.top + this.page.bottom
    },
    pageCount() {
      // 单位都是 pt
      var docHeight = this.getApiInstance().docStore.docHeight / 4 * 3
      var allContentHeight = docHeight - this.page.top - this.page.bottom
      var pageCount = allContentHeight / this.page.contentHeight
      pageCount = Math.ceil(pageCount)
      return Math.max(1, pageCount) || 0
    },
    page() {
      return this.getApiInstance().docStore.page
    },
    style() {
      var style = {}
      style.width = this.page.width + 'pt'
      style['min-height'] = this.page.height + 'pt'
      'top left right bottom'.split(' ').forEach(key => {
        style['padding-' + key] = this.page[key] + 'pt'
      })
      return style
    }
  },
  methods: {
    async tryLazyLoad() {
      await this.$nextTick()
      setTimeout(() => {
        var contentSize = this.element?.children?.length || 0
        if (this.renderCount >= contentSize) {
          return
        }
        this.renderCount += 30
        this.tryLazyLoad()
      }, 0)
    },
    getText(el) {
      return utils.getText(el)
    },
    onCellsChange(cells) {
      console.log('render', cells.length)
    },
    handleClick() {
      var editor = this.getApiInstance()
      if (editor.docStore.selectedCommentId != null) {
        editor.emit('comment', {
          type: 'click',
          data: { comments: [] }
        })
        editor.docStore.selectedCommentId = null
        if (editor.docStore.activeComment) {
          if (editor.docStore.activeComment.temp) {
            editor.comments.delete(editor.docStore.activeComment.commentId)
          }
          editor.docStore.activeComment = null
        }

        editor.comments.updateStatus()
      }
    }
  }
}
</script>

<style scoped>
.collection-cluster {
  overflow: auto;
  margin: 0 auto;
  height: 100%;
  /* background: #fafafa; */
  /* border: 1px solid #dfdfdf; */
}
</style>
