<template>
  <!-- <div class="editor" @click="$refs.hiddenInput.focus()"> -->
  <div class="word-container" :style="containerStyle">
    <div class="word-editor" :style="editorStyle">
      <!-- <word-style v-if="doc" :styles="doc.styles"></word-style> -->
      <word-style></word-style>

      <div ref="word" class="word-pages">
        <word-page
          ref="page"
          v-if="docDocument"
          :element="body"
          :contenteditable="config.canEdit"
          @dragstart.native.prevent
          @paste.native="onPaste"
          @beforeinput.native="onBeforeInput"
          @compositionstart.native="onCompositionStart"
          @compositionend.native="onCompositionEnd"
          @keydown.native="onKeyDown">
        </word-page>
        <word-toolbar
          v-if="config.canEdit || config.canComment"
          :config="config"
          :scale="scale"
          @showAddComment="showAddComment"></word-toolbar>
        <word-comment-popover
          v-if="docDocument"
          :value="activeComment"
          @submit="updateComment"
          @remove="removeComment">
        </word-comment-popover>
        <span class="word-info" @click="infoPanelVisible = !infoPanelVisible">i</span>
        <word-info-panel v-if="infoPanelVisible" :zip="zip"></word-info-panel>
        <BrandFooter></BrandFooter>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewResults from '@/components/ReviewResults'
import WordStyle from '@/word/word-style.vue'
import WordToolbar from '@/word/word-toolbar.vue'
import WordPage from '@/word/word-page.vue'
import WordInfoPanel from '@/word/word-info-panel.vue'
import store from '@/store'
import * as utils from '@/word/utils'
import Editor from '@/editor'
import BrandFooter from '@/word/brand-footer.vue'
import WordCommentPopover from '@/word/word-comment-popover'
import Hotkeys from '@/hotkeys'

const Parser = require('@/parser')
const Serializer = require('@/serializer')

export default {
  components: {
    WordStyle,
    WordToolbar,
    WordPage,
    ReviewResults,
    WordInfoPanel,
    BrandFooter,
    WordCommentPopover
  },
  props: {
    file: {
      type: null
    },
    options: {
      type: Object
    }
  },
  data() {
    return {
      cursor: store.state.cursor,
      inputValue: '',
      config: this.getDefaultConfig(),
      zip: null,
      scale: 100,
      toolbarVisible: true,
      infoPanelVisible: false,
      isTyping: false,
      docDocument: null
    }
  },
  provide() {
    return {
      getApiInstance: () => {
        if (!this.editor) {
          this.editor = new Editor({
            events: this
          })
          window.docxjseditor = window.PowerOffice = this
        }
        return this.editor
      }
    }
  },
  computed: {
    body() {
      if (this.docDocument && this.editor) {
        return this.editor.getElementByNamePath('body')
      }
    },
    activeComment() {
      return this.editor.docStore.activeComment
    },
    computedProps() {
      return [this.file, this.options]
    },
    // scale 方案模仿腾讯文档
    containerStyle() {
      var scale = this.scale / 100

      var pageWidth = this.editor?.docStore?.page?.width
      if (!pageWidth) {
        return
      }
      var pageEl = this.$refs.page.$el
      return {
        width: pageEl.offsetWidth * scale + 'px',
        height: pageEl.offsetHeight * scale + 'px'
      }
    },
    editorStyle() {
      var scale = this.scale / 100

      var pageWidth = this.editor?.docStore?.page?.width
      if (!pageWidth) {
        return
      }
      var pageEl = this.$refs.page.$el
      var deltaWidth = pageEl.offsetWidth * (scale - 1) / 2
      var deltaHeight = pageEl.offsetHeight * (scale - 1) / 2
      var transform = `matrix(${scale}, 0, 0, ${scale}, ${deltaWidth}, ${deltaHeight})`
      return {
        transform,
        width: 1 / scale * 100 + '%'
      }
    }
  },
  mounted() {
    this.editor.root = this.$refs.word
  },
  watch: {
    computedProps: {
      handler() {
        if (this.file) {
          this.handleDocxBlob(this.file, this.options)
        }
      },
      immediate: true
    }
  },
  methods: {
    setScale(val) {
      if (val >= 50 && val <= 300) {
        this.scale = val
      }
      return this.scale
    },
    getTOC(...args) {
      return this.editor.getTOC(...args)
    },
    scrollToId(...args) {
      return this.editor.scrollToId(...args)
    },
    format(...args) {
      return this.editor.format(...args)
    },
    async testRange(...args) {
      var commentId
      await this.editor.handleAction(() => {
          commentId = this.editor.comments.insert(this.editor.getRange(...args), {})
      })
      this.editor.comments.select(commentId)
      console.log('test range comment id', commentId)
    },
    // TODO 如何把内部函数隐藏起来
    async updateComment(text) {
      var activeComment = this.editor.docStore.activeComment
      var commentId = activeComment?.commentId
      if (!commentId) return
      if (activeComment.temp) {
        this.editor.comments.update(commentId, {
          text
        })
      } else {
        this.editor.comments.reply(commentId, {
          text
        })
      }
      this.editor.comments.select(commentId)
    },
    async removeComment() {
      var commentId = this.editor.docStore.activeComment?.commentId
      if (!commentId) return
      this.editor.docStore.activeComment = null
      this.editor.handleAction(() => {
        this.editor.comments.delete(commentId)
      })
    },
    async showAddComment() {
      var range = this.editor.getSelectRange()
      if (range) {
        var top = this.editor.getSelectionTop()
        window.getSelection().empty()
        var commentId
        if (!range.isValid) {
          console.log('invalid selection range', range)
          return
        }

        await this.handleAction(() => {
          commentId = this.editor.comments.insert(range, {
            date: new Date(),
            author: this.config.author,
            text: '',
            temp: true // 临时批注
          })
          this.editor.docStore.selectedCommentId = commentId
        })
      }

      this.editor.docStore.activeComment = {
        date: new Date(),
        author: this.config.author,
        text: '',
        top,
        temp: true,
        commentId,
        canEdit: true
      }
    },
    getDefaultConfig() {
      return {
        canEdit: false,
        canComment: false,
        author: 'PowerOffice Lite'
      }
    },
    onCompositionStart(ev) {
      var config = this.editor.config
      if (!config.canEdit) return
      ev.preventDefault()
      this.isTyping = true
      this.editor.cacheSelection()
    },
    onCompositionEnd(ev) {
      var config = this.editor.config
      if (!config.canEdit) return
      this.isTyping = false
      this.editor.history.belongOneOpList(() => {
        this.editor.insertText(ev.data)
      })
      this.editor.clearSelectionCache()
    },
    onPaste(ev) {
      ev.preventDefault()
      var clipDataText = ev.clipboardData.getData('text/plain')
      this.editor.insertText(clipDataText)
    },
    async onKeyDown(ev) {
      if (Hotkeys.isUndo(ev)) {
        this.editor.history.undo()
      } else if (Hotkeys.isRedo(ev)) {
        this.editor.history.redo()
      }
    },
    async onBeforeInput(ev) {
      ev.preventDefault()
      var config = this.editor.config
      if (!config.canEdit) return

      const { inputType } = ev
      this.editor.history.belongOneOpList(async () => {
        if (inputType === 'deleteContentBackward') {
          await this.editor.deleteForward()
        } else if (inputType === 'deleteContentForward') {
          await this.editor.deleteBackward()
        } else if (inputType === 'insertParagraph') {
          await this.editor.insertBreak()
        } else if (inputType === 'insertText') {
          if (!this.isTyping) {
            await this.editor.insertText(ev.data)
          }
        }
      })
    },
    testBlob() {
      var file = document.querySelector('input').files[0]
      var url = URL.createObjectURL(file)
      window.open(url, '_blank')
    },
    reset() {
      utils.sharedData.numState = {}
      this.zip = null
      this.infoPanelVisible = false
      this.config = this.getDefaultConfig()
      this.editor.docStore.reset()
    },
    async handleDocxBlob(blob, config) {
      var start = Date.now()
      this.reset()
      config = Object.assign(this.getDefaultConfig(), config)
      var parser = new Parser()
      try {
        var doc = await parser.parseDocx(blob)
      } catch (rawErr) {
        var err = new Error()
        err.message = '文件无法打开'
        err.detail = rawErr.message
        this.$emit('error', err)
        return
      }
      this.zip = doc.zip
      delete doc.zip
      doc = _.cloneDeep(doc)
      this.editor.docStore.update(doc)
      this.editor.blob = blob
      this.editor.config = this.config = config
      this.editor.zip = this.zip
      this.comments = this.editor.comments
      this.docDocument = this.editor.docStore.document
      setTimeout(() => {
        this.$emit('ready')
        console.log(`docxjs ${this.editor.version} perf (${this.body?.children.length})para`, Date.now() - start + 'ms')
      })
    },

    async exportDocx() {
      const blob = await this.serializeDocx()
      const link = document.createElement('a')
      link.download = this.editor.fileName || this.editor.blob.name || 'UnTitled.docx'
      link.href = URL.createObjectURL(blob)
      link.click()
    },
    serializeDocx() {
      var serializer = new Serializer()
      return serializer.format({
        zip: this.zip,
        docStore: this.editor.docStore
      })
    },
    getRange(...args) {
      return this.editor.getRange(...args)
    },
    getSelectRange() {
      return this.editor.getSelectRange()
    },
    handleAction(...args) {
      return this.editor.handleAction(...args)
    },
    sleep(time) {
      return new Promise(resolve => {
        setTimeout(resolve, time)
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/word/word.scss";

.hidden-input {
  position: absolute;
  opacity: 0;
}
</style>
