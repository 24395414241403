<template>
  <div class="word-table-wrapper">
    <table class="word-table" :style="tableStyle">
      <colgroup>
        <col
          v-for="(item, i) in tblGrid"
          :key="i"
          :width="dxa2px(item)">
      </colgroup>
      <tr
        v-for="(row, i) in rows"
        :key="i"
        :style="row.style">
        <td
          v-for="(cell, i) in row.children"
          :key="i"
          :colspan="cell.colspan"
          :rowspan="cell.rowspan"
          :style="cell.style">
          <word-element :element="cell"></word-element>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import * as utils from '@/word/utils'
import _ from 'lodash'

export default {
  mixins: [WordMixin],
  components: {
    WordElement: () => import('@/word/word-element.vue')
  },
  name: 'word-tbl',
  props: {
    element: Object
  },
  data() {
    return {
      name: 'tbl'
    }
  },
  computed: {
    tblGrid() {
      var editor = this.getApiInstance()
      var tblGrid = editor.getElementByNamePath('tblGrid', this.element)
      return _.map(tblGrid?.children, item => {
        return item?.attrs['w:w']
      })
    },
    styleId() {
      var editor = this.getApiInstance()
      return editor.getElementByNamePath(`${this.name}Pr.${this.name}Style.val`, this.element)
    },
    stylePr() {
      var editor = this.getApiInstance()
      var style = editor.docStore.styleIdMap[this.styleId]
      return style?.[`${this.name}Pr`]
    },
    inlinePr() {
      var editor = this.getApiInstance()
      var props = editor.rawPr2dict(editor.getElementByNamePath(`${this.name}Pr`, this.element))
      return props
    },
    computedPr() {
      var defaultStyle = this.getApiInstance().docStore.defaultStyleMap.table
      return {
        ...defaultStyle?.[`${this.name}Pr`],
        ...this.stylePr,
        ...this.inlinePr
      }
    },
    tableStyle() {
      var editor = this.getApiInstance()
      var width = 0
      this.tblGrid.forEach(item => {
        width += +item
      })
      var align = editor.getElementByNamePath('tblPr.jc.val', this.element)
      var page = PowerOffice.editor.docStore.page
      var style = {
        width: this.dxa2px(width)
      }
      if (align === 'center') {
        style.marginLeft = (page.contentWidth - width / 20) / 2 + 'pt'
      } else if (align === 'right') {
        style.marginLeft = (page.contentWidth - width / 20) + 'pt'
      }
      var tblpPr = editor.getElementByNamePath('tblPr.tblpPr', this.element)
      if (tblpPr) {
        var x = tblpPr.attrs['w:tblpX']
        if (x) {
          style.marginLeft = x / 20 - page.left + 'pt'
        }
        var y = tblpPr.attrs['w:tblpY']
        if (y) {
          style.marginTop = y / 20 + 'pt'
        }
      }
      return style
    },
    rows() {
      var editor = this.getApiInstance()
      var tableBorders = this.computedPr.tblBorders
      var rows = []
      _.each(this.element.children, (row, rowIndex) => {
        if (row.name !== 'w:tr') {
          return
        }
        var rowBorders = this.getBordersStyle(row, 'tblPrEx.tblBorders')
        var cells = []
        var trHeight = editor.getElementByNamePath('trPr.trHeight.val', row) || 0
        var rowStyle = {
          'height': this.dxa2px(trHeight)
        }
        var gridSpanCount = 0
        _.each(row.children, (cell) => {
          if (cell.name !== 'w:tc') {
            return
          }
          var tcPr = editor.rawPr2dict(editor.getElementByNamePath('tcPr', cell))
          var colspan = tcPr?.gridSpan?.val
          gridSpanCount += (Number(colspan) || 1)
          var rowspan = null
          var vMergeObject = tcPr?.vMerge
          var tcBolders = this.getBordersStyle(cell, 'tcPr.tcBorders')
          var bordersList = [tableBorders, rowBorders, tcBolders]
          if (vMergeObject && vMergeObject.val === 'restart') {
            rowspan = 1
            for (let i = 1; ; i++) {
              var rowBelow = this.element.children[rowIndex + i]
              if (!rowBelow) {
                break
              }
              var cellBelow = this.findCellBelow(rowBelow, gridSpanCount)

              var tcPrBelow = editor.rawPr2dict(editor.getElementByNamePath('tcPr', cellBelow))
              var cellBelowVMergeObject = tcPrBelow?.vMerge
              if (cellBelowVMergeObject && cellBelowVMergeObject.val !== 'restart') {
                rowspan++
                let borders = this.getBordersStyle(cellBelow, 'tcPr.tcBorders')
                borders.vMerge = true
                bordersList.push(borders)
              } else {
                break
              }
            }
          } else if (vMergeObject) {
            // 有 vMerge 的默认值就是 continue，不一定有
            return
          }
          var vAlign = editor.getElementByNamePath('tcPr.vAlign.val', cell)
          var tblPr = this.computedPr
          var computedBordersStyle = this.getComputedBorderStyle(...bordersList)
          var cellStyle = {
            'padding-left': this.dxa2px(tblPr.tblCellMar?.left?.w),
            'padding-right': this.dxa2px(tblPr.tblCellMar?.right?.w),
            'vertical-align': vAlign === 'center' ? 'middle' : vAlign,
            'background-color': utils.normalizeColor(editor.getElementByNamePath('tcPr.shd.fill', cell)),
            'color': utils.normalizeColor(editor.getElementByNamePath('tcPr.shd.color', cell)),
            ...computedBordersStyle
          }
          cells.push({
            children: cell.children,
            colspan,
            rowspan,
            style: cellStyle,
            type: 'cell'
          })
        })
        rows.push({
          style: rowStyle,
          children: cells,
          type: 'row'
        })
      })
      return rows
    }
  },
  methods: {
    findCellBelow(rowBelow, gridSpanCount) {
      var editor = this.getApiInstance()
      for (let i = 0; i < rowBelow.children.length; i++) {
        var cell = rowBelow.children[i]
        if (cell.name !== 'w:tc') {
          continue
        }
        var tcPr = editor.rawPr2dict(editor.getElementByNamePath('tcPr', cell))
        var colspan = tcPr?.gridSpan?.val || 1
        gridSpanCount -= colspan
        if (gridSpanCount <= 0) {
          return cell
        }
      }
    },
    getBordersStyle(element, path) {
      var editor = this.getApiInstance()
      var borders = editor.getElementByNamePath(path, element)
      var ret = editor.rawPr2dict(borders)
      return ret
    },
    getComputedBorderStyle(...args) {
      // debugger
      // table, row, cell
      var posList = 'top bottom left right'.split(' ')
      var styleMap = {}
      _.each(args, item => {
        _.each(posList, pos => {
          styleMap[pos] = styleMap[pos] || {}
          if (item) {
            if (item.vMerge && item[pos]?.val === 'nil') {
              // vMerge 的border只能新增不能nil
              return
            }
            Object.assign(styleMap[pos], item[pos])
          }
        })
      })
      var ret = {}
      for (var key in styleMap) {
        var item = styleMap[key]
        var width = item.sz / 8
        var borderStyle = ''
        if (item.val === 'single') {
          borderStyle = 'solid'
        } else if (item.val === 'double') {
          borderStyle = 'double'
        }
        var color = utils.normalizeColor(item.color) || '#000'
        if (width && borderStyle) {
          // 和 word 一样直接不展示，wps 的假装虚线会有覆盖问题
          ret[`border-${key}`] = `${width}pt ${borderStyle} ${color}`
        }
      }
      return ret
    }
  }
}
</script>
