export default class History {
  constructor(editor) {
    this.undos = []
    this.redos = []
    this.isRecording = true // 是否在记录历史
    this.editor = editor
    this.currentOpList = []
  }

  async undo() {
    if (this.undos.length === 0) {
      return
    }
    this.isRecording = false
    var undoItem = this.undos.pop()
    var opList = undoItem.opList
    opList = opList.map(op => this.inverse(op))
    for (let op of opList) {
      await this.apply(op, 'undo')
    }
    this.redos.push(undoItem)
    this.isRecording = true
  }

  async redo() {
    if (this.redos.length === 0) {
      return
    }
    this.isRecording = false
    var redoItem = this.redos.pop()
    var opList = redoItem.opList.slice().reverse()
    for (let op of opList) {
      await this.apply(op, 'redo')
    }
    this.undos.push(redoItem)
    this.isRecording = true
  }

  async apply(op, action) {
    const { type } = op
    if (type === 'remove_text') {
      this.editor.deleteBackwardByPoint({
        path: op.path,
        offset: op.offset
      }, op.text)
    } else if (type === 'insert_text') {
      this.editor.insertText(op.text, {
        path: op.path,
        offset: op.offset
      })
    } else if (type === 'remove_comment') {
      await this.editor.handleAction(() => {
        this.editor.comments.delete(op.comment.id)
      })
    } else if (type === 'insert_comment') {
      // TODO 插入批注比较复杂，可能也是先插入空，再修改 text，但这个需要合并成一个操作
    } else if (type === 'set_node') {
      var el = this.editor.getElementByPath(op.path)
      if (action === 'undo') {
        this.editor.setPr(el, op.oldProps, op.path, true)
      } else {
        this.editor.setPr(el, op.props, op.path, false)
      }
    } else if (type === 'insert_node') {
      console.log(222222, type, op.path.join())
      this.editor.appendToPath(op.path, op.node)
    }
  }

  add(opList) {
    if (!this.isRecording) {
      // 非录制状态不记录历史
      return
    }
    if (!Array.isArray(opList)) {
      opList = [opList]
    }
    this.undos.push({
      time: Date.now(),
      opList
    })
  }

  pushOp(op) {
    this.currentOpList.push(op)
  }

  async belongOneOpList(handler) {
    this.currentOpList = []
    await handler()
    if (this.currentOpList.length) {
      this.undos.push({
        time: Date.now(),
        opList: this.currentOpList
      })
      this.currentOpList = []
    }
  }

  inverse(op) {
    switch (op.type) {
      case 'insert_text': {
        return { ...op, type: 'remove_text' }
      }
      case 'remove_text': {
        return { ...op, type: 'insert_text' }
      }
      case 'insert_comment': {
        return { ...op, type: 'remove_comment' }
      }
      case 'remove_comment': {
        return { ...op, type: 'insert_comment' }
      }
      case 'set_node': {
        return { ...op }
      }
      case 'insert_node': {
        return { ...op, type: 'remove_node' }
      }
      case 'remove_node': {
        return { ...op, type: 'insert_node' }
      }
    }
  }
}
