<template>
  <div class="zip-content" v-if="zip">
    <div v-for="(item, i) in zip.files" :key="i" class="item" @click="handleClick(item)">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
const mime = require('mime')
const path = require('path')

export default {
  props: {
    zip: Object
  },
  methods: {
    async handleClick(item) {
      if (item.name.endsWith('/')) {
        return
      }
      var zipFile = this.zip.file(item.name)
      var content = await zipFile.async('blob')
      var ext = path.extname(item.name).slice(1)
      var file = new File([content], item.name, {
        type: mime.getType(ext) || 'text/xml' // 默认使用 text/xml, 例如 .rels
      })
      var url = URL.createObjectURL(file)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.zip-content {
  line-height: 1;
}

.item {
  cursor: pointer;
  margin-bottom: 0.5em;
  &:hover {
    color: blue;
  }
}
</style>
