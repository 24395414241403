<template>
  <a :href="href" class="word-hyperlink" :class="tocClass" :target="anchor ? null : '_blank'" :style="style">
    <slot></slot>
  </a>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import * as utils from '@/word/utils.js'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  computed: {
    style() {
      return {}
    },
    anchor() {
      return this.element.attrs?.['w:anchor']
    },
    tocClass() {
      // class 和 行内属性谁优先?
      var ret = {}
      if (this.element.children) {
        var isTabRun = this.element.children.some(item => {
          if (item.name === 'w:tab') {
            return true
          }
          return item.children.some(item => item.name === 'w:tab')
        })
        if (isTabRun) {
          ret['word-toc-tab-wrapper'] = true
        }
      }
      return ret
    },
    href() {
      if (this.anchor) {
        return `#` + this.anchor
      }
      var text = utils.getText(this.element)
      return text
    }
  },
  methods: {
  }
}
</script>
