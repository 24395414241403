<template>
  <div class="word-info-panel">
    <div class="word-info-bar">
      <div class="word-info-tab"
        v-for="(item, i) in tabs"
        @click="activeTab = item"
        :class="{ 'word-active': item === activeTab }"
        :key="i">{{ item }}</div>
    </div>
    <div class="word-info-main">
      <div class="word-basic-info" v-if="activeTab === 'Info'">
        <div v-for="(item, i) in propKeys" :key="i">
          <span class="word-info-key">{{ item }}</span>
          <span>{{ docProps[item] || '-' }}</span>
        </div>
      </div>
      <div class="word-info-comments" v-if="activeTab === 'Comments'">
        <div v-for="(item, i) in comments" class="word-info-comment" :key="i">
          <div class="word-info-comment-attr">
            <span>{{ item.author }}</span>
            <span>{{ item.date }}</span>
          </div>
          <div>{{ item.text }}</div>
        </div>
      </div>
      <ZipContent :zip="zip" v-if="activeTab === 'Xml'"></ZipContent>
      <TableOfContent v-if="activeTab === 'Menu'"></TableOfContent>
    </div>
  </div>
</template>

<script>
import ZipContent from '@/components/ZipContent'
import TableOfContent from '@/components/TableOfContent'

export default {
  components: {
    ZipContent,
    TableOfContent
  },
  inject: ['getApiInstance'],
  props: ['zip'],
  data() {
    var editor = this.getApiInstance()
    var docStore = editor.docStore
    var appArr = docStore.docPropsApp?.[0]?.children || []
    var coreArr = docStore.docPropsCore?.[0]?.children || []
    var arr = appArr.concat(coreArr)
    var docProps = {}
    arr.forEach(item => {
      var key = item.name.split(':').slice(-1)[0]
      docProps[key] = item.text
    })
    docProps.fileName = editor.blob.name
    docProps.fileSize = editor.blob.size
    return {
      tabs: ['Info', 'Comments', 'Menu', 'Xml'],
      activeTab: 'Info',
      propKeys: ['fileName', 'fileSize', 'creator', 'lastModifiedBy', 'created', 'modified', 'Application', 'AppVersion', 'Template', 'Characters', 'Words', 'Lines', 'Paragraphs', 'Company', 'CharactersWithSpaces'],
      docProps,
      comments: docStore.allComments
    }
  }
}
</script>
