<template>
  <span
    class="word-bookmark-start"
    :data-path="getPath().join('-')"
    :id="anchorId"></span>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import rangeStore from '@/components/rangestore'

export default {
  mixins: [WordMixin],
  created() {
    var point = {
      path: this.getPath(),
      offset: 0
    }
    rangeStore.addStart(`bookmark-${this.element.id}`, point)
  },
  computed: {
    anchorId() {
      return this.element.attrs?.['w:name']
    }
  }
}
</script>
