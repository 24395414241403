<template>
  <component :is="'style'" v-html="styleText" id="word-style">
  </component>
</template>

<script>
import * as utils from '@/word/utils'

export default {
  data() {
    return {
      styleText: ''
    }
  },
  inject: ['getApiInstance'],
  created() {
    this.getApiInstance().docStore.$on('update', () => {
      this.styleText = this.getStyleText()
    })
  },
  methods: {
    getStyleText() {
      var styleIdMap = this.getApiInstance().docStore.styleIdMap
      var styles = Object.values(styleIdMap)
      styles = styles.map(item => {
        var basedOn = item.basedOn?.val
        if (basedOn) {
          var basedStyle = styleIdMap[basedOn]
        }
        var rPr = Object.assign({}, basedStyle?.rPr, item.rPr)
        var pPr = Object.assign({}, basedStyle?.pPr, item.pPr)
        var props = Object.assign({}, pPr, rPr)
        var style = utils.props2style(props)
        if (!_.isEmpty(style)) {
          var styleContent = Object.entries(style).map(([key, val]) => {
            return `${key}: ${val};`
          }).join('\n')
          return `.word-style-${item.styleId} {
            ${styleContent}
          }`
        }
      }).filter(item => item)
      return styles.join('\n\n')
    },
    getStyleIdMap() {
      var map = {}
      var styles = this.styles?.styles?.content || []
      styles.forEach(style => {
        map[style.styleId] = style
      })
      return map
    }
  }
}
</script>
