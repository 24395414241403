<template>
  <component
    :is="tagName"
    :data-tag="element.tagName"
    :data-key="element.id"
    :element="this.isTagSupported() ? element : null">
    <template v-if="showList.length">
      <word-element
        v-for="child in lazyShowList || []"
        :key="child.id"
        :element="child"></word-element>
    </template>
    <!-- 确保段落有高度 -->
    <span v-else-if="element.name === 'w:p'">&ZeroWidthSpace;</span>
  </component>
</template>

<script>
import _ from 'lodash'
import * as utils from '@/word/utils'
import WordT from '@/word/word-t.vue'
import WordBr from '@/word/word-br.vue'
import WordTbl from '@/word/word-tbl.vue'
import WordP from '@/word/word-p.vue'
import WordPDebug from '@/word/word-p-debug.vue'
import WordR from '@/word/word-r.vue'
import WordNum from '@/word/word-num.vue'
import WordDrawing from '@/word/word-drawing.vue'
import WordObject from '@/word/word-object.vue'
import WordDelText from '@/word/word-del-text.vue'
import WordDel from '@/word/word-del.vue'
import WordIns from '@/word/word-ins.vue'
import WordCommentRangeStart from '@/word/word-comment-range-start.vue'
import WordCommentRangeEnd from '@/word/word-comment-range-end.vue'
import WordBookmarkStart from '@/word/word-bookmark-start.vue'
import WordBookmarkEnd from '@/word/word-bookmark-end.vue'
import WordTab from '@/word/word-tab.vue'
import WordHyperlink from '@/word/word-hyperlink.vue'
import WordCommentReference from '@/word/word-comment-reference.vue'
import WordAlternateContent from '@/word/word-alternate-content.vue'
import WordShape from '@/word/word-shape.vue'
import WordTxbxContent from '@/word/word-txbx-content.vue'
import WordSdt from '@/word/word-sdt.vue'
import WordSdtContent from '@/word/word-sdt-content.vue'
import WordPict from '@/word/word-pict.vue'

export default {
  name: 'word-element',
  components: {
    WordT,
    WordBr,
    WordTbl,
    WordP,
    WordPDebug,
    WordR,
    WordNum,
    WordDrawing,
    WordObject,
    WordDelText,
    WordDel,
    WordIns,
    WordCommentRangeStart,
    WordCommentRangeEnd,
    WordBookmarkStart,
    WordBookmarkEnd,
    WordTab,
    WordHyperlink,
    WordCommentReference,
    WordAlternateContent,
    WordShape,
    WordTxbxContent,
    WordSdt,
    WordSdtContent,
    WordPict
  },
  props: {
    element: Object
  },
  inject: ['getApiInstance'],
  data() {
    return {
      renderCount: 30 // 预渲染30个child
    }
  },
  computed: {
    showList() {
      return utils.filterAndIndexElements(this.element?.children)
    },
    lazyShowList() {
      // return this.element.children
      if (this.renderCount) {
        return this.showList.slice(0, this.renderCount)
      }
      return this.showList // TODO 触发大量重绘
    },
    tagNameWithoutNS() {
      return this.element.name?.split(':').slice(-1)[0]
    },
    tagName() {
      if (this.tagNameWithoutNS === 'rect') {
        return 'word-shape'
      }
      if (this.isTagSupported()) {
        return 'word-' + this.tagNameWithoutNS
      }
      return 'div'
    }
  },
  mounted() {
    this.tryLazyLoad()
  },
  render(h) {
    return h('div', '1234')
  },
  methods: {
    async tryLazyLoad() {
      await this.$nextTick()
      setTimeout(() => {
        var contentSize = this.element.children?.length || 0
        if (this.renderCount >= contentSize) {
          return
        }
        this.renderCount += 30
        this.tryLazyLoad()
      }, 0)
    },
    isTagSupported() {
      return utils.SUPPORTED_TAGS.has(this.tagNameWithoutNS)
    }
  }
}
</script>
