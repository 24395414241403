<template>
  <div v-show="visible" class="word-toolbar" ref="toolbar" :style="style" @mousedown.stop.prevent>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.h1 }">
      <i class="docxfont docx-h2" @click="toggleHeading(1)"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.h2 }">
      <i class="docxfont docx-h" @click="toggleHeading(2)"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.h3 }">
      <i class="docxfont docx-h3" @click="toggleHeading(3)"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.align === 'left' }">
      <i class="docxfont docx-juzuo" @click="toggleAlign('left')"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.align === 'center' }">
      <i class="docxfont docx-juzhong" @click="toggleAlign('center')"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.align === 'right' }">
      <i class="docxfont docx-juyou" @click="toggleAlign('right')"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.bold }">
      <i class="docxfont docx-jiacu" @click="toggleBold"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.underline }">
      <i class="docxfont docx-xiahuaxian" @click="toggleUnderline"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.italic }">
      <i class="docxfont docx-xieti" @click="toggleItalic"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit" :class="{ 'word-btn-active': state.strike }">
      <i class="docxfont docx-shanchuxian" @click="toggleStrike"></i>
    </div>
    <div class="icon-wrapper" v-if="config.canEdit || config.canComment">
      <span class="docxfont word-toolbar-text" @click.stop="showAddComment">添加批注</span>
    </div>
    <!-- <div class="icon-wrapper">
      <i class="docxfont icon-youxuliebiao"></i>
    </div>
    <div class="icon-wrapper">
      <i class="docxfont icon-wuxuliebiao"></i>
    </div>
    <div class="icon-wrapper">
      <i class="docxfont icon-pinglun"></i>
    </div> -->
  </div>
</template>

<script>
import _ from 'lodash'
import * as utils from '@/word/utils'
import * as Path from '@/path'

export default {
  props: {
    config: {
      type: Object
    },
    scale: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      style: {},
      state: {
        bold: false,
        underline: false,
        strike: false,
        italic: false,
        h1: false,
        h2: false,
        h3: false
      }
    }
  },
  inject: ['getApiInstance'],
  created() {
    document.addEventListener('selectionchange', () => {
      var selection = document.getSelection()
      if (selection.isCollapsed) {
        this.visible = false
      }
    })
    document.addEventListener('mouseup', (ev) => {
      var editor = this.getApiInstance()
      var target = ev.target
      if (!editor.root.contains(target)) {
        return
      }
      if (target.tagName === 'TEXTAREA') {
        return
      }
      var selection = document.getSelection()
      if (!selection.isCollapsed) {
        this.visible = true
        editor.cacheSelection()
      } else {
        editor.clearSelectionCache()
      }
    })
  },
  watch: {
    visible(val) {
      if (val) {
        this.updateState()
      }
    }
  },
  methods: {
    updateState() {
      const domSelection = window.getSelection()
      var el = this.$refs.toolbar
      var wordPageEl = el.parentElement
      const domRange = domSelection.getRangeAt(0)
      const rect = domRange.getBoundingClientRect()
      var wordPageRect = wordPageEl.getBoundingClientRect()
      var finalTop = rect.top + window.pageYOffset - wordPageRect.top
      var finalLeft = rect.left +
          window.pageXOffset +
          rect.width / 2 - wordPageRect.left
      finalTop = finalTop / (this.scale / 100)
      finalLeft = finalLeft / (this.scale / 100)
      this.style = {
        top: finalTop + 'px',
        left: finalLeft + 'px'
      }
      var selection = window.getSelection()
      var dom = selection.focusNode
      if (dom.nodeType === 3) {
        dom = dom.parentElement
      }

      // 字体属性回显
      var runElement = dom.parentElement // run 上面才有样式
      var computedStyle = getComputedStyle(runElement)

      this.state = {
        bold: computedStyle.fontWeight > 400,
        underline: computedStyle.textDecorationLine === 'underline',
        strike: computedStyle.textDecorationLine === 'line-through',
        italic: computedStyle.fontStyle === 'italic',
        h1: false,
        h2: false,
        h3: false,
        align: null
      }

      // 段落属性回显
      var paraElement = runElement.closest('.word-p')
      if (paraElement) {
        var paraComputedStyle = getComputedStyle(paraElement)
        this.state.align = paraComputedStyle['text-align']
        var styleId = /word-style-(\w+)/.exec(paraElement.className)?.[1]
        if (styleId) {
          var headingLevel = this.getApiInstance().docStore.styleHeadingIdMap[styleId]
          this.state[`h${headingLevel}`] = true
        }
      }
    },
    toggleHeading(level) {
      // 飞书中的 H1, H2, H3 都是段落属性
      var docStore = this.getApiInstance().docStore
      var pStyleId = docStore.styleNameIdMap[`heading ${level}`]
      this.toggleParaProp({
        'w:pStyle': this.state[`h${level}`] ? null : {
          'w:val': pStyleId
        }
      })
    },
    toggleAlign(align) {
      var isActive = this.state.align === align
      this.toggleParaProp({
        'w:jc': isActive ? null : {
          'w:val': align
        }
      })
    },
    toggleBold() {
      this.toggleProp({
        'w:b': this.state.bold ? {
            'w:val': '0'
        } : null
      })
    },
    toggleUnderline() {
      this.toggleProp({
        'w:u': this.state.underline ? {
          'w:val': '0'
        } : null
      })
    },
    toggleItalic() {
      this.toggleProp({
        'w:i': this.state.italic ? {
          'w:val': '0'
        } : null
      })
    },
    toggleStrike() {
      this.toggleProp({
        'w:strike': this.state.strike ? {
          'w:val': '0'
        } : null
      })
    },
    showAddComment() {
      this.$emit('showAddComment')
      this.visible = false
    },
    toggleParaProp(prop) {
      // 切换段落属性
      var editor = this.getApiInstance()
      var keyRange = editor.getSelectKeyRange({ tagName: 'p' })
      var active = false
      editor.history.belongOneOpList(() => {
        editor.nodes({
          pass: (path, node) => {
            if (node.name !== 'w:p') {
              return
            }
            if (keyRange.end.key === node.id) {
              editor.setPr(node, prop, path)
              active = false
              return true
            }
            if (node.id === keyRange.start.key) {
              editor.setPr(node, prop, path)
              active = true
              return
            }
            if (active) {
              editor.setPr(node, prop, path)
            }
          }
        })
      })
      editor.clearSelection()
    },
    toggleProp(prop) {
      var editor = this.getApiInstance()
      var keyRange = editor.getSelectKeyRange({ tagName: 'r' })
      var active = false

      editor.history.belongOneOpList(() => {
        // TODO 必须改成 run 级别的, 否则 run 已经变了, 但到了 t 级别才发现
        editor.nodes({
          pass(path, node) {
            if (node.name !== 'w:r') {
              return
            }
            var runElement = node
            var paraElement = editor.getElementByPath(path.slice(0, -1))
            var runIndex = path.slice(-1)[0]

            if (node.id === keyRange.end.key && keyRange.end.key === keyRange.start.key) {
              // 在同一个 run 中
              var runLength = utils.getText(node).length
              if (keyRange.end.offset === runLength && keyRange.start.offset === 0) {
                // 正好就是这个节点
                editor.setPr(runElement, prop, path)
                return true
              }
              if (keyRange.end.offset !== runLength) {
                paraElement.children.splice(runIndex + 1, 0, editor.splitRun(runElement, keyRange.end.offset)[1])
              }
              if (keyRange.start.offset !== 0) {
                paraElement.children.splice(runIndex + 1, 0, editor.splitRun(runElement, keyRange.start.offset)[1])
              }
              editor.setPr(paraElement.children[runIndex + 1], prop, Path.next(path))
              return true
            }

            if (node.id === keyRange.end.key) {
              active = false
              // 此时 node 是 text node
              if (keyRange.end.offset !== 0) {
                var newRunElement = editor.splitRun(runElement, keyRange.end.offset)[1]
                paraElement.children.splice(runIndex + 1, 0, newRunElement)
                editor.setPr(runElement, prop, path)
              }
              return true
            }

            if (node.id === keyRange.start.key) {
              // 此时 node 是 text node
              active = true
              if (keyRange.start.offset !== 0) {
                paraElement.children.splice(runIndex + 1, 0, editor.splitRun(runElement, keyRange.start.offset)[1])
              } else {
                editor.setPr(runElement, prop, path)
              }
              return
            }

            if (active) {
              editor.setPr(node, prop, path)
            }

          }
        })
      })

      editor.clearSelection()
    }
  }
}
</script>
