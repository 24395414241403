const cheerio = require('cheerio')

class Parser {
  parse(xmlString) {
    var $ = cheerio.load(xmlString, {
      xml: {
        normalizeWhitespace: false // 可以区分大小写
      }
    })
    this.$ = $
    return this.parseElement($.root()[0])
  }

  parseElement(element) {
    var ret = {
      name: element.name
    }
    if (element.children) {
      element.children.forEach(child => {
        if (child.type === 'tag') {
          ret.children = ret.children || []
          ret.children.push(this.parseElement(child))
        } else if (child.type === 'text') {
          ret.text = child.data
        }
      })
    }
    if (element.attribs && Object.keys(element.attribs).length) {
      ret.attrs = element.attribs
    }
    return ret
  }
}

module.exports = Parser

async function test() {
  var xml = await fs.readFile('./public/test/comment/word/document.xml', 'utf8')
  var parser = new Parser()
  var json = parser.parse(xml)
  var serializer = new Serializer()
  var newXml = serializer.format(json)
  await fs.writeFile('./dist/test.xml', newXml)
  await fs.writeFile('./dist/test.json', JSON.stringify(json, 0, 4))
}
