
<template>
  <img
    class="word-drawing"
    :src="imageUrl"
    :style="style"
    :width="emu2css(width)"
    :height="emu2css(height)">
</template>
<script>
import WordMixin from '@/word/word-mixin'

export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  data() {
    return {
      imageUrl: ''
    }
  },
  async created() {
    this.imageUrl = await this.getImageUrl()
  },
  computed: {
    style() {
      if (!this.imageUrl) {
        return {
          visibility: 'hidden'
        }
      }
    },
    middleEl() {
      return this.getApiInstance().getElementByNamePath('inline', this.element) || this.getApiInstance().getElementByNamePath('anchor', this.element)
    },
    width() {
      return this.getApiInstance().getElementByNamePath(`extent.cx`, this.middleEl)
    },
    height() {
      return this.getApiInstance().getElementByNamePath(`extent.cy`, this.middleEl)
    },
    embedId() {
      return this.getApiInstance().getElementByNamePath(`graphic.graphicData.pic.blipFill.blip.embed`, this.middleEl)
    },
    relatedFilePath() {
      const relationShips = this.getApiInstance().docStore.relationships[0]?.children || []
      const relationShip = relationShips.filter(item => item.attrs.Id === this.embedId)[0]
      if (!relationShip) {
        return
      }
      var filePath = 'word/' + relationShip.attrs.Target
      return filePath
    }
  },
  methods: {
    async getImageUrl() {
      const filePath = this.relatedFilePath
      var file = this.getApiInstance().zip.file(filePath)
      if (!file) {
        return
      }
      var blob = await file.async('blob')
      return URL.createObjectURL(blob)
    }
  }
}
</script>
