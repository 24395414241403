<template>
  <component
    :is="tagName"
    :data-tag="element.tagName"
    :element="this.isTagSupported() ? element : null">
    <span>{{text}}</span>
    <!-- <template v-if="element.children && element.children.length">
      <word-p-debug
        v-for="child in element.children || []"
        :key="child.id"
        :element="child"></word-p-debug>
    </template> -->
    <!-- 确保段落有高度 -->
    <!-- <span v-else>&ZeroWidthSpace;</span> -->
  </component>
</template>

<script>
import * as utils from '@/word/utils'

export default {
  props: {
    element: Object
  },
  computed: {
    text() {
      console.log('render')
      return utils.getText(this.element)
    },
    tagNameWithoutNS() {
      return this.element.name?.split(':').slice(-1)[0]
    },
    tagName() {
      if (this.isTagSupported()) {
        return 'word-' + this.tagNameWithoutNS
      }
      return 'div'
    }
  },
  methods: {
    isTagSupported() {
      return utils.SUPPORTED_TAGS.has(this.tagNameWithoutNS)
    }
  }
}
</script>
