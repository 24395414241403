import _ from 'lodash'
import Vue from 'vue'
import * as utils from '@/word/utils.js'

export default function (editor) {
  var docStore = new Vue({
    data() {
      var data = this.getDefault()
      return data
    },
    computed: {
      allComments() {
        var comments = this?.comments?.[0]?.children || []
        comments = comments.map(comment => {
          return {
            author: comment.attrs['w:author'],
            date: comment.attrs['w:date'],
            id: comment.attrs['w:id'],
            initials: comment.attrs['w:initials'],
            text: utils.getText(comment)
          }
        })
        return comments
      },
      numAbstractMap() {
        // 内部序号映射表 abstract id
        var map = {}
        _.each(this.numbering?.[0].children, item => {
          if (item.name === 'w:abstractNum') {
            var abstractId = item.attrs['w:abstractNumId']
            map[abstractId] = {}
            _.filter(item.children, item => item.name === 'w:lvl').forEach(item => {
              var level = editor.rawPr2dict(item)
              map[abstractId][level.ilvl] = level
            })
          }
        })
        return map
      },
      num2abstract() {
        // 内部序号映射表 num id
        var map = {}
        _.each(this.numbering?.[0].children, item => {
          if (item.name === 'w:num') {
            var numId = item.attrs['w:numId']
            var abstractId = item.children[0].attrs['w:val']
            map[numId] = abstractId
          }
        })
        return map
      },
      numMap() {
        // 面向用户使用
        var map = {}
        for (var key in this.num2abstract) {
          map[key] = this.numAbstractMap[this.num2abstract[key]]
        }
        return map
      },
      styleNameIdMap() {
        var map = {}
        _.each(this.styles?.[0]?.children, item => {
          var styleId = item.attrs?.['w:styleId']
          var nameAttr = _.find(item.children, item => item.name === 'w:name')
          var styleName = nameAttr?.attrs?.['w:val']
          if (styleName) {
            map[styleName.toLowerCase()] = styleId
          }
        })
        return map
      },
      styleIdMap() {
        var map = {}
        _.each(this.styles?.[0]?.children, item => {
          var styleId = item.attrs?.['w:styleId']
          var val = editor.rawPr2dict(item)
          map[styleId] = val
        })
        return map
      },
      defaultStyleMap() {
        var ret = {}
        for (var styleId in this.styleIdMap) {
          var style = this.styleIdMap[styleId]
          if (style.default) {
            ret[style.type] = style
          }
        }
        return ret
      },
      styleHeadingIdMap() {
        var styleIdMap = this.styleIdMap || {}
        var headingIdMap = {}
        for (let styleId in styleIdMap) {
          var outlineLvl = styleIdMap[styleId]?.pPr?.outlineLvl?.val
          if (outlineLvl) {
            headingIdMap[styleId] = Number(outlineLvl) + 1
          }
        }
        return headingIdMap
      },
      styleIdNameMap() {
        return _.invert(this.styleNameIdMap)
      },
      defaultTabStop() {
        var defaultTabStop = editor.getElementByNamePath('defaultTabStop.val', editor.docStore.settings[0])
        defaultTabStop = Number(defaultTabStop) || 0
        defaultTabStop = defaultTabStop / 20
        return defaultTabStop + 'pt'
      }
    },
    methods: {
      reset() {
        Object.assign(this, this.getDefault())
      },
      async update(val) {
        val = Object.assign({}, val)
        // 这两个是性能大户，需要freeze，防止OOM
        if (val.styles) {
          val.styles = Object.freeze(val.styles)
        }
        if (val.numbering) {
          val.numbering = Object.freeze(val.numbering)
        }
        Object.assign(this, val)
        await editor.handleAction()
        this.$emit('update')
      },
      getDefault() {
        return Vue.observable({
          document: null,
          numbering: null,
          comments: null,
          docPropsCore: null,
          docPropsApp: null,
          settings: null,
          page: null,
          activeComment: null, // 用于展示的批注面板
          key2path: {},
          bottomMap: {}, // 当前渲染最下方，用于绝对定位+分页辅助
          commentsDisplayInfo: {}, // 纯前端的批注展示样式信息：是否显示，颜色
          commentsExtended: null,
          styles: null, // 不应该变化, 可以直接 freeze
          selectedCommentId: null,
          docHeight: 0 // 文档高度
        })
      },
      updateDocHeight: _.debounce(function() {
        var el = document.querySelector('.word-page')
        console.log('update doc height', el.offsetHeight)
        this.docHeight = el.offsetHeight
      }, 300, {
        trailing: true
      })
    }
  })
  return docStore
}
