<template>
  <span class="word-sdt" :class="customClass">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    element: Object
  },
  inject: ['getApiInstance'],
  computed: {
    customClass() {
      var editor = this.getApiInstance()
      var val = editor.getElementByNamePath('sdtPr.docPartObj.docPartGallery.val', this.element)
      if (/Table of Contents/i.test(val)) {
        return {
          'word-sdt-toc': true
        }
      }
    }
  }
}
</script>
