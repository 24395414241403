<template>
  <div v-if="false" class="word-brand-footer">
    <div class="word-brand-text">📝 PowerOffice Lite {{ version }} 提供技术支持</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version: VERSION
    }
  }
}
</script>

<style scoped lang="scss">
.word-brand-footer {
  margin: 10px 0;
  user-select: none;
}
.word-brand-text {
  position: relative;
  text-align: center;
  font-size: 12px;
  color: rgba(0,0,0,.3);
  font-family: PingFang SC, 微软雅黑;
}
</style>
