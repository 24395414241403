<template>
  <div class="word-shape" :style="wrapStyle">
    <div :style="style" ref="el">
      <word-element v-if="finalElement" :element="finalElement"></word-element>
    </div>
  </div>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import qs from 'min-qs'
import * as utils from '@/word/utils'

// 水平对齐基于 mso-position-horizontal
// 高度自适应文字内容
// 宽度是页面宽度比例
export default {
  mixins: [WordMixin],
  props: {
    element: Object
  },
  components: {
    WordElement: () => import('@/word/word-element.vue')
  },
  // async mounted() {
  //   var el = this.$refs.el
  //   if (typeof ResizeObserver === 'function') {
  //     // Chrome 64，Edge 79 支持此特性
  //     new ResizeObserver(() => {
  //       var docStore = this.getApiInstance().docStore
  //       docStore.bottomMap = {
  //         ...docStore.bottomMap,
  //         [this.element.id]: el.offsetTop + el.offsetHeight
  //       }
  //     }).observe(el)
  //   }
  // },
  computed: {
    parsedStyle() {
      return qs.parse(this.element?.attrs?.style, ';', ':')
    },
    wrapStyle() {
      // 绝对定位的同时还占位
      return {
        display: 'inline-block',
        width: this.style.width,
        height: this.style.height
      }
    },
    style() {
      var style = this.parsedStyle
      var editor = this.getApiInstance()
      var width = parseFloat(style['width']) // pt
      var align = this.parsedStyle['mso-position-horizontal']
      var anchor = this.parsedStyle['mso-position-horizontal-relative'] // 锚点是页面或者页面距
      var pageContentWidth = editor.docStore.page.contentWidth // pt
      var marginLeft = 0

      if (align === 'center') {
        marginLeft = (pageContentWidth - width) / 2
      } else if (align === 'right') {
        marginLeft = (pageContentWidth - width)
      } else {
        marginLeft = parseFloat(style['margin-left'])
      }

      if (marginLeft < -200) {
        // 脏处理，有可能出现-669pt的文本框
        marginLeft += pageContentWidth
      }

      var ret = {
        'position': 'absolute',
        'left': anchor === 'page' ? 0 : editor.docStore.page.left + 'pt',
        'margin-left': marginLeft + 'pt',
        'margin-top': style['margin-top'],
        'width': style['width'],
        'height': style['height'], // 高度还是不对
        'display': 'inline-block',
        'padding': '4px',
        'z-index': style['z-index'] < 0 ? 0 : style['z-index'],
        'background-color': utils.normalizeColor(this.element?.attrs?.fillcolor) || 'none',
        'border': this.getBorderStyle() || 'none'
      }
      return Object.assign(ret, this.textboxStyle)
    },
    textboxStyle() {
      var editor = this.getApiInstance()
      var ret = editor.getElementByNamePath('textbox', this.element)
      var style = qs.parse(ret?.attrs?.style, ';', ':')
      var writingMode
      var layout = style['layout-flow']
      if (layout && layout.includes('vertical')) {
        writingMode = 'vertical-rl'
        if (style['mso-layout-flow-alt'] === 'top-to-bottom') {
          writingMode = 'vertical-lr'
        }
      }
      if (writingMode) {
        return {
          writingMode
        }
      }
    },
    finalElement() {
      var editor = this.getApiInstance()
      var ret = editor.getElementByNamePath('textbox.txbxContent', this.element)
      return ret
    }
  },
  methods: {
    getBorderStyle() {
      var stroke = _.find(this.element.children, item => item.name === 'v:stroke')
      if (stroke) {
        var width = stroke.attrs?.weight
        var color = utils.normalizeColor(stroke.attrs?.color)
        var style = 'solid'
        if (stroke.attrs?.dashstyle) {
          style = 'dashed'
        }
        if (width && color) {
          return `${width} ${style} ${color}`
        }
      }
    }
  }
}
</script>
