<template>
  <div
    v-if="value"
    class="word-popover word-popover-active"
    :class="popClass"
    :style="style"
    @click.stop>
    <!-- 理论上可同时用于批注，修订等 -->
    <div
      class="word-comment-item"
      v-for="(item, i) in list"
      :key="i">
      <div class="word-comment-attr">
        <span class="word-comment-author">
          <span>{{ item.author }}</span>
          <span class="word-comment-type">{{ cnType }}</span>
        </span>
        <span class="word-comment-time">{{ item.dateStr }}</span>
      </div>
      <div class="word-comment-text">
        <span>{{ item.text }}</span>
      </div>
    </div>
    <div class="word-comment-inner">
      <textarea
        v-if="value && value.commentId"
        v-model="text"
        ref="textarea"
        class="word-textarea"
        rows="1"
        @input="resizeTextArea"
        :placeholder="placeholder"
        @keydown.enter="handleEnter"></textarea>
      <div v-if="text" class="word-btn-line">
        <span class="word-btn word-btn-text" @click="handleCancel">取消</span>
        <span class="word-btn word-btn-primary" @click="handleConfirm">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      text: ''
    }
  },
  watch: {
    async value(value) {
      if (value) {
        await this.$nextTick()
        var textarea = this.$refs.textarea
        if (textarea) {
          if (value.temp) {
            // 插入批注才 focus
            textarea.focus()
          }
          var popover = textarea.closest('.word-popover')
          if (popover && popover.scrollIntoViewIfNeeded) {
            popover.scrollIntoViewIfNeeded()
          }
        }
      } else {
        this.text = ''
      }
    },
    text() {
      this.resizeTextArea()
    }
  },
  inject: ['getApiInstance'],
  computed: {
    style() {
      return {
        top: this.value.top + 'px'
      }
    },
    placeholder() {
      if (this.value.temp) {
        return '插入批注'
      }
      return '回复批注'
    },
    list() {
      var list = []
      if (this.value) {
        list.push(this.value)
        if (this.value.replys) {
          list.push(...this.value.replys)
        }
      }
      list = list.map(item => {
        return {
          ...item,
          dateStr: this.getDateStr(item.date)
        }
      })
      return list
    },
    popClass() {
      return {
        'word-popover-comment': this.cnType === '批注',
        'word-popover-revision': this.cnType === '修订',
        'word-popover-comment-edit': this.cnType === '批注' && this.value.canEdit
      }
    },
    cnType() {
      if ('commentId' in this.value) {
        return '批注'
      }
      return '修订'
    }
  },
  methods: {
    paddingNum(num) {
      if (Number(num) < 10) {
        return '0' + num
      }
      return num
    },
    async resizeTextArea() {
      await this.$nextTick()
      var textarea = this.$refs.textarea
      if (!textarea) {
        return
      }
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    },
    getDateStr(date) {
      if (typeof date === 'string') {
        date = date.replace(/z$/i, '') // 去掉时区信息
        date = new Date(date)
      }
      var month = this.paddingNum(date.getMonth() + 1)
      var year = date.getFullYear()
      var day = this.paddingNum(date.getDate())
      var hours = this.paddingNum(date.getHours())
      var minutes = this.paddingNum(date.getMinutes())
      return `${year}-${month}-${day} ${hours}:${minutes}`
    },
    handleEnter(ev) {
      if (ev && (ev.ctrlKey || ev.shiftKey)) {
        // 换行
        if (ev.ctrlKey) {
          this.text += '\n'
        }
      } else {
        // 提交
        ev.preventDefault()
        if (this.text) {
          this.$emit('submit', this.text)
          this.text = ''
        }
      }
    },
    handleConfirm() {
      this.$emit('submit', this.text)
      this.text = ''
    },
    async handleCancel() {
      if (this.value.temp) {
        this.$emit('remove')
      }
      this.text = ''
    }
  }
}

</script>

<style scoped lang="scss">
.word-popover {
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Arial,Segoe UI,PingFang SC,Microsoft Yahei,Hiragino Sans GB,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  user-select: none;
  position: absolute;
  font-size: 12px;
  width: 270px;
  z-index: 99;
  color: #333;
  border-radius: 6px;
  border: 1px solid #dee0e3;
  right: -282px;
  background: #fff;
  padding: 6px 0 12px 0;
}

.word-comment-inner {
  padding: 0 12px;
}

.word-comment-item {
  padding: 6px 12px;
}

.word-comment-author {
  flex: 1;
  margin-right: 1em;
  overflow: hidden;
}

.word-popover-active {
  box-shadow: 0 8px 16px rgba(31, 35, 41, 0.1);
}

.word-comment-time {
  color: rgb(143, 149, 158);
}

.word-comment-type {
  padding-left: 5px;
  color: rgb(143, 149, 158);
}

.word-comment-attr {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  color: rgb(143, 149, 158);
}

.word-comment-text {
  margin-top: 6px;
  font-size: 14px;
  white-space: break-spaces;
}

.word-textarea {
  display: block;
  overflow: hidden;
  resize: none;
  width: 100%;
  padding: 4px 11px;
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Arial,Segoe UI,PingFang SC,Microsoft Yahei;
  border: 1px solid rgb(208, 211, 214);
  outline: none;
  font-size: 14px;
  line-height: 22px;
  user-select: unset;
  border-radius: 6px;
  margin-top: 10px;
  &::placeholder {
    color: rgb(143, 149, 158);
  }
}

.word-btn-line {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-top: 6px;
}
</style>

