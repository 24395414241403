<template>
  <component
    :is="tocId ? 'a' : 'div'"
    class="word-p"
    :href="tocId ? '#' + tocId : null"
    :style="style"
    :class="pClass"
    :data-para-id="element.paraId">
    <word-page-break v-if="hasPageBreakBefore" :id="element.id"></word-page-break>
    <word-num v-if="numPr" :numPr="numPr" :numStr="element.numStr" :style="numStyle"></word-num><slot></slot>
    <word-page-break v-if="hasPageBreakAfter" :id="element.id"></word-page-break>
  </component>
</template>

<script>
import WordMixin from '@/word/word-mixin'
import _ from 'lodash'
import * as utils from '@/word/utils.js'
import WordNum from '@/word/word-num.vue'
import WordPageBreak from '@/word/word-page-break'

export default {
  mixins: [WordMixin],
  components: { WordNum, WordPageBreak },
  props: {
    element: Object
  },
  computed: {
    tocId() {
      return this.element.tocId
    },
    styleId() {
      var editor = this.getApiInstance()
      return editor.getElementByNamePath('pPr.pStyle.val', this.element)
    },
    numPr() {
      return this.computedPPr?.numPr || this.relatedStyle?.pPr?.numPr
    },
    numStyle() {
      var style = {}
      var ind = this.computedPPr?.ind
      if (ind) {
        if (ind.hanging) {
          style['min-width'] = ind.hanging / 20 + 'pt'
        }
      }
      return style
    },
    numInfo() {
      if (this.inlinePPr?.numPr) {
        return this.getApiInstance().numbering.getNumInfo(this.inlinePPr.numPr)
      }
    },
    hasPageBreakAfter() {
      return _.some(this.element.children, item => {
        return _.some(item.children, item => {
          if (item.name === 'br' && item.type === 'page') {
            return true
          }
        })
      })
    },
    hasPageBreakBefore() {
      return _.some(this.element.children, item => {
        return _.some(item.children, item => {
          if (item.name === 'w:lastRenderedPageBreak') {
            return true
          }
        })
      })
    },
    pClass() {
      var ret = {}
      var styleId = this.styleId
      if (styleId != null) {
        ret[`word-style-${styleId}`] = true
      } else {
        var matched = this.getApiInstance().docStore.defaultStyleMap.paragraph
        if (matched) {
          ret[`word-style-${matched.styleId}`] = true
        }
      }
      var tabStyle = this.computedPPr.tabs?.tab?.leader
      if (tabStyle) {
        ret[`word-tab-${tabStyle}`] = true
      }
      if (this.tocId) {
        ret['word-p-toc'] = true
      }
      return ret
    },
    stylePPr() {
      var editor = this.getApiInstance()
      var style = editor.docStore.styleIdMap[this.styleId]
      return style?.pPr
    },
    inlinePPr() {
      var editor = this.getApiInstance()
      var props = editor.rawPr2dict(editor.getElementByNamePath('pPr', this.element))
      return props
    },
    computedPPr() {
      // 亲测 inline 最优先，numInfo 次之，最后 style，是属性级别覆盖
      var ind = {}
      var indArr = [this.inlinePPr?.ind, this.numInfo?.pPr?.ind, this.stylePPr?.ind]
      var gotIndType = false
      indArr.forEach(item => {
        if (!item) return
        for (var key in item) {
          if (key in ind) {
            continue
          }
          if (key === 'hanging' || key === 'firstLine' || key === 'firstLineChars') {
            if (gotIndType) continue
            gotIndType = true
          }
          ind[key] = item[key]
        }
      })
      var numPPr = this.numInfo?.pPr || {}
      return {
        ...this.stylePPr,
        ...numPPr,
        ...this.inlinePPr,
        ind
      }
    },
    style() {
      // 样式继承 http://officeopenxml.com/WPstyles.php
      var props = this.computedPPr
      if (!props) return
      var style = {}
      var sz = props.rPr?.sz
      if (sz) {
        // 字体提升一级，因为段落缩进，行高等依赖字体大小
        props.sz = sz
      }
      Object.assign(style, utils.props2style(props)) // 可覆盖 num 中的样式
      Object.assign(style, this.getLineHeightStyle())

      // 单独计算 tab-size
      // 制表位左对齐，pos就是右边tab结束的位置（参考标尺）
      // 制表位右对齐，pos是右边文字全部结束的位置，用于目录
      // 因此目录永远是右对齐 39.52个字符，而一行也是39.52个字符
      var tab = props.tabs?.tab
      if (tab) {
        // TODO 此处处理有问题，会导致各种问题
        var tabSize = tab.pos / 20 // pos 是 tab 到页面到的位置，比如目录都是 8300
        var align = tab.val
        if (tabSize) {
          // var magicNumber = 6
          if (style['padding-left']) {
            tabSize -= parseFloat(style['padding-left'])
          }
          if (align === 'right') {
            // 制表位右对齐，pos是右边文字全部结束的位置，一般用于目录
            // 因此目录永远是右对齐 39.52个字符，而一行也是39.52个字符
            // 这种撑满的形态css中需要用 flex
            if (this.tocId) {
              // style.width = tabSize + 'pt' // 不理解？
              style.display = 'flex'
            }
          } else {
            // 制表位左对齐，pos就是右边tab结束的位置（参考标尺）
            // style['tab-size'] = tabSize + 'pt'
          }
          // style['tab-size'] = tabSize * 1 * 4 / 3 + 'px'
        }
      }
      if (this.tocId) {
        // 如果是目录，忽略首行缩进
        if (style['text-indent']) {
          style['padding-left'] = style['text-indent']
          delete style['text-indent']
        }
      }
      return style
    },
    relatedStyle() {
      var editor = this.getApiInstance()
      var styleList = this.getApiInstance().docStore.styles?.[0]?.children
      var matched = _.find(styleList, item => {
        var styleId = item.attrs?.['w:styleId']
        if (this.styleId) {
          return styleId == this.styleId
        }
        var name = editor.getElementByNamePath('name.val', item)
        return name === 'Normal'
      })
      return editor.rawPr2dict(matched)
    }
  },
  methods: {
    getLineHeightStyle() {
      var props = this.computedPPr
      var editor = this.getApiInstance()
      // lineHeight 依赖文字大小，需要自己 content 和 style，只能自己算
      var maxFontSize = _.max(_.map(this.element.children, item => {
        return Number(editor.getElementByNamePath('rPr.sz.val', item)) || Number(editor.getElementByNamePath('rPr.szCs.val', item))
      }))

      var relatedStyle = this.relatedStyle || editor.docStore.defaultStyleMap.paragraph
      if (relatedStyle) {
        var styleFontSize = Number(relatedStyle.rPr?.sz?.val) || Number(relatedStyle.rPr?.szCs?.val)
        maxFontSize = Math.max(maxFontSize || 0, styleFontSize || 0)
      }

      var spacing = props.spacing
      if (!spacing) {
        // 必须有 spacing，固定值可以不要 font size
        return null
      }

      var lineHeightStyle = editor.getLineHeight(maxFontSize / 2, spacing)
      return lineHeightStyle
    }
  }
}
</script>
